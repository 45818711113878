
.slide__item {
    position: relative;
}



.slide__item--animated img {
    transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    -webkit-backface-visibility: hidden;
    transform: scale(1.2);
}

.slick-active .slide__item--animated img {
    transform: scale(1);
}

/*
* — Hero Slider —
*/

.hero__slide-item {
    height: calc(100vh + 82px);
    width:100%;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transform:scale(1.25);

}


.hero__slide-item:before {
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   display: block;
   background: radial-gradient(circle, rgba(0,0,0,0) 0%, #000000 100%);
   z-index: 1;
   opacity: 0.2;
}


.hero__slider .slick-dots {
    position: absolute;
    right: calc(66rem/16);
    top:calc(100vh - 50px);
    z-index: 2;
    padding-left: 0;
    list-style: none;
    @media screen and (max-width: 767px) {
        right: calc(15rem/16);
        top: calc(100vh - 83px);
    }
}

.hero__slider .slick-dots button {
    display:none;
}

.hero__slider .slick-dots li {
    display:inline-block;
    padding-left:calc(4rem/16);
    cursor:pointer;
}

.hero__slider .slick-dots li:before {
    content:'';
    display:block;
    width:calc(60rem/16);
    height:calc(4rem/16);
    background-color: rgba(255,255,255,0.4);
    @media screen and (max-width: 767px) {
        width:calc(30rem/16);
        height:calc(2rem/16);
    }
}

.hero__slider .slick-dots li.slick-active:before {
    background-color: rgba(255,255,255,1);
}



.thumb-slider .slick-dots, .img-text-slider .slick-dots, .teaser-row-slider .slick-dots, .js-teaser-slider-mobile .slick-dots, .js-comparison-teaser-slider .slick-dots {
    display:none !important;
}


.slider--has-rectangle:before {
    @media screen and (min-width: 768px) {
        position:absolute;
        bottom:calc(-92rem/16);
        height:calc(228rem/16);
        width:100%;
        content:'';
        left:0;
        display:block;
        z-index: -1;
        background-color:var(--color-grey-light);
    }
}

