.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(15rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: calc(26rem/16);
    min-width: calc(26rem/16);
    height: calc(26rem/16);
    margin-top: .1em;
    display:block;
}
.custom-checkbox__input:hover + .custom-checkbox__box,
.custom-checkbox__input:focus-visible + .custom-checkbox__box{
    border: 2px solid var(--color-primary);
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(12rem/16);
    color: var(--color-primary);
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    color:var(--color-primary);
}

.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary-light);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-size:calc(18rem/16);
}

.custom-checkbox__text p{
    margin: 0;
}

.custom-checkbox__text a{
    color: var(--color-primary);
    text-decoration: underline;
}
.custom-checkbox__text a:hover,
.custom-checkbox__text a:focus,
.custom-checkbox__text a:active{
    text-decoration: none;
}


.checkbox-button {
    cursor:pointer;
    width: 100%;
    border:1px solid var(--color-primary);
    color:var(--color-primary);
    background: #fff;
    margin-top: calc(40rem/16);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size:calc(12rem/16);
    font-family:var(--font-default-bold);
    height:calc(57rem/16);
    padding:0 calc(30rem/16);
}

.checkbox-button.active {
    background-color:var(--color-primary);
    color:#fff;
}

.checkbox-button .custom-checkbox__text {
    font-size:calc(12rem/16);
}

.checkbox-button .custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color:var(--color-primary-light);
    color:var(--color-primary);
}

.checkbox--error-left{
    position: relative;
}

.checkbox--error-left .parsley-errors-list li{
    text-align: left;
}