

.blockquote {
    color:var(--color-primary);
    font-size:calc(21rem/16);
    font-family:var(--font-headline);
    text-align:center;
    padding-top:calc(50rem/16);
    padding-bottom:calc(50rem/16);

    @media screen and (max-width: 767px) {
        padding-top:calc(30rem/16);
        padding-bottom:calc(30rem/16);
    }
}

.blockquote__author {
    color:var(--color-primary-light);
    padding-top:calc(8rem/16);
    font-family:var(--font-headline-italic);
    text-align:right;
    font-size:calc(18rem/16);
}