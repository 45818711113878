/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}

ol, ul {
    padding-left:0px;
}


ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul:not(.list-unstyled):not(.slick-dots):not(.button-style-icon):not(.parsley-errors-list) li:before{
    content: "■";
    color:var(--color-primary);
    font-size:calc(12rem/16);
    margin-right: calc(24rem/16);
    margin-left: -2rem;
    vertical-align: .1em;
    box-shadow: 0 0.625rem 1.875rem 0 rgba(0,0,0,.1);
}
.wysiwyg ul>li {
    padding-left: 2rem;
}

ul:not(.list-unstyled, .breadcrumb):not(.slick-dots):not(.button-style-icon):not(.parsley-errors-list).text-white li:before{
    color:#fff;
}

ol:not(.list-unstyled, .breadcrumb) {
    counter-reset: item;
}
ol:not(.list-unstyled, .breadcrumb) li { display: block; }
ol:not(.list-unstyled, .breadcrumb) li + li { padding-top: calc(20rem/16);}

ol:not(.list-unstyled, .breadcrumb) li:before {
    content: counter(item) ". ";
    counter-increment: item;
    font-weight: bold;
}



.form-control {
    font-size:calc(18rem/16);
    line-height:calc(24/18);
}