
.main-footer__container {
    padding: calc(64rem/16) calc(100rem/16);
    margin-top:calc(50rem/16);
    background-color: #E0E0E0;
    position:relative;
    overflow:hidden;
    @media screen and (max-width: 1400px) {
        padding: calc(64rem/16) calc(75rem/16);
    }
    @media screen and (max-width: 1199px) {
        padding: calc(40rem/16) calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-top: calc(70rem/16);
        margin-top:calc(20rem/16);
    }
}

.main-footer__meta-infos {
    padding: 0 calc(100rem/16);
    height:calc(50rem/16);
    font-size:calc(12rem/16);
    @media screen and (max-width: 1400px) {
        padding: calc(20rem/16) calc(70rem/16);
        height:auto;
    }
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(20rem/16) calc(60rem/16);
        font-size: calc(13rem/16);
        display: flex;
    }
}

.main-footer__meta-infos a:focus-visible,
.main-footer__meta-infos a:hover {
    color:var(--color-primary);
}
.main-footer__address {
    @media screen and (max-width: 767px) {
        margin-bottom:calc(50rem/16);

    }
}

.main-footer__address a:hover {
    color:var(--color-primary-dark);
}
.main-footer__link-icon {
    font-size:calc(50rem/16);
    @media screen and (max-width: 1400px) {
        font-size:calc(30rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(30rem/16);

    }
}

.main-footer__link-icon img {
    width:calc(54rem/16);
    padding-bottom:calc(10rem/16);
}

.main-footer__links {
    max-width:calc(600rem/16);
}

.main-footer__links a:focus-visible .main-footer__link,
.main-footer__links a:hover .main-footer__link {
    color:var(--color-primary-dark);
}

.main-footer__link {
    letter-spacing:1px;
    color:var(--color-primary);
    line-height:calc(18/13);
    font-size:calc(13rem/16);
    text-transform: uppercase;
    font-family:var(--font-default-bold);
    @media screen and (max-width: 1199px) {
        font-size: calc(12rem/16);
        letter-spacing:0;
    }
}

.main-footer__link-list {
    border-left:1px solid #000;
    padding-left:calc(28rem/16);
    line-height: calc(27/15);
    font-size:calc(15rem/16);
    @media screen and (max-width: 1400px) and (min-width: 1200px) {
        padding-left: calc(10rem/16);
        font-size: calc(13rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-left: calc(0rem/16);
        font-size: calc(13rem/16);
        border-left:none;
    }
    @media screen and (max-width: 767px) {
        border-top:1px solid #000;
        border-left:0px;
        margin-top: calc(30rem/16);
        text-align: center;
        padding-top: calc(30rem/16);
        padding-bottom: calc(30rem/16);
        padding-left:0px;

    }
}

.main-footer__link-list a:focus-visible,
.main-footer__link-list a:hover{
    color:var(--color-primary);
}


.main-footer__logo {
    width: calc(287rem/16);
    max-width:100%;
    @media screen and (max-width: 1400px) {
        width:90%;
    }
    @media screen and (max-width: 1199px) {
        margin-left:auto;
    }
    @media screen and (max-width: 767px) {
        max-width:calc(300rem/16);
        display:flex;
        margin: calc(20rem/16) auto 0;
    }
}
.main-footer__logo--dreilaendergiro {
    width: 5.9375rem;
    @media screen and (max-width: 1400px) {
        width:100%;
        max-width:  5.9375rem;
    }
    @media screen and (max-width: 767px) {
        max-width:  calc(100rem/16);
    }
}

.main-footer__social-media {
    width:calc(200rem/16);
    margin-top: calc(15rem/16);
    @media screen and (max-width: 767px) {
        margin: calc(30rem/16) auto 0 !important;
    }
}
.main-footer__social-media-title {
    font-size:calc(13rem/16);
    color:var(--color-primary);
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    text-align:left;
    margin-bottom:calc(5rem/16);
    letter-spacing:1px;
    @media screen and (max-width: 767px) {
        text-align:center;
    }
}

.main-footer__phone, .main-footer__mail {
    color:var(--color-primary);
    font-size:calc(19rem/16);
    line-height: calc(33/19);
    letter-spacing:1px;
    @media screen and (max-width: 1400px) {
        font-size: calc(16rem/16);
    }
}

.main-footer__phone .icon {
    font-size:calc(22rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(16rem/16);
    }

}
.main-footer__mail .icon {
    font-size:calc(16rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(11rem/16);
    }
}
.main-footer__social-media-item {
    background-color:#fff;
    width:calc(50rem/16);
    height:calc(50rem/16);
    display:inline-flex;
    align-items:center;
    justify-content:center;
    color:var(--color-primary);
    font-size:calc(16rem/16);
    line-height:1;
    transition:all 0.2s ease;
    border-right:1px solid #E0E0E0;
}

.main-footer__social-media-item:hover {
    background-color:var(--color-primary);
    color:#fff;
}
.main-footer__valantic-logo {
    margin-bottom:calc(1rem/16);
}


.main-footer__partner-logos {
    height:calc(23rem/16);
}
.main-footer__leader-logo {
    height: calc(44rem/16);
    @media (min-width: 768px) {
        height: calc(60rem/16);
    }
}

/* new footer styling */
.main-footer--new .main-footer__container {
    background-color: var(--color-grey-light);

    @media (max-width: 767px) {
        padding-left: calc(16rem/16);
        padding-right: calc(16rem/16);
    }
}

.main-footer--new .main-footer__address {
    @media (max-width: 767px) {
        margin-bottom: calc(16rem/16);
    }
}

.main-footer--new .main-footer__address-subtitle {
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    letter-spacing: calc(1rem/16);
}

.main-footer--new .main-footer__address-title {
    font-family: var(--font-headline-italic);
    font-size: calc(26rem/16);
    line-height: calc(32/26);
    color: var(--color-primary);
    margin-top: calc(12rem/16);
}

.main-footer--new .main-footer__address-info {
    margin-top: calc(32rem/16);
    font-size: calc(16rem/16);
    line-height: calc(24/16);
}

.main-footer__link-btn-list {
    @media (max-width: 767px) {
        padding-bottom: calc(36rem/16);
        border-bottom: calc(1rem/16) solid #DCDCDC;
    }
}

.main-footer--new .main-footer__link-btn-item + .main-footer__link-btn-item {
    margin-top: calc(12rem/16);
}

.main-footer--new .main-footer__link-icon-btn {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    line-height: calc(12/12);
    letter-spacing: calc(1rem/16);
    position: relative;
    padding: calc(6rem/16) 0 calc(6rem/16) calc(40rem/16);
    display: block;
}

.main-footer--new .main-footer__link-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.main-footer--new .main-footer__link-icon.icon {
    font-size: calc(20rem/16);
}

.main-footer--new img.main-footer__link-icon {
    height: calc(20rem/16);
    width: auto;
}

.main-footer--new .main-footer__link-list {
    border: none;
    padding-left: 0;
    font-size: calc(16rem/16);
    line-height: calc(24/16);

    @media (max-width: 767px) {
        margin: 0;
        padding: 0;
    }
}

.main-footer--new .main-footer__link-list li + li {
    margin-top: calc(12rem/16);
}

.main-footer--new .main-footer__social-media {
    margin: 0;

    @media (max-width: 767px) {
        margin-top: 0;
    }
}

.main-footer--new .main-footer__social-media-title {
    font-size: calc(12rem/16);
    line-height: calc(12/12);
}

.main-footer--new .main-footer__social-media-item {
    color: var(--color-text-default);
    height: calc(48rem/16);
    width: calc(48rem/16);
    border: none;

    &:hover {
        color: #fff;
    }
}

.main-footer--new .main-footer__logo {
    margin-top: calc(36rem/16);
}

.main-footer--new .main-footer__meta-infos {
    padding-bottom: calc(80rem/16);
}
.main-footer--new .main-footer__meta-infos.main-footer__meta-infos--kaunertal {
    @media (min-width: 768px) {
        padding-bottom: calc(112rem/16);
    }
}