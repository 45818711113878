.lightbox-caption {
    max-width:calc(1030rem/16);
    margin:0 auto;
    color:var(--color-text-default);
}

.lightbox-caption__title {
    font-size:calc(23rem/16);
    font-family:var(--font-default-bold);
    line-height:calc(28/23);
    padding-bottom:calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
        padding-bottom:calc(10rem/16);
    }
}

.lightbox-caption__title:after {
    content:'';
    display:block;
    width:calc(102rem/16);
    height:calc(4rem/16);
    background-color:var(--color-primary);
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    margin-top:calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-top:calc(5rem/16);
        height:calc(3rem/16);
    }
}

.lightbox-caption__infos {
    font-size:calc(15rem/16);
    font-family:var(--font-default);
    line-height:calc(27/15);
    letter-spacing: 1px;
    margin-bottom:calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(12rem/16);
        line-height:1.1;
    }
}

.lightbox-caption__gdpr-policy {
    margin-top:calc(18rem/16);
    padding-top:calc(18rem/16);
    border-top: 1px solid var(--color-primary);
}