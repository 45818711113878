
.list--has-seperator-line li+li:before {
    @media screen and (min-width: 768px) {
        content: '|';
        position: absolute;
        left: 0;
        color: #000;
    }
}
.list--has-seperator-line li:not(:last-child) {
    @media screen and (min-width: 768px) {
        margin-right: .5rem;
    }
}
.list--has-seperator-line li {
    position:relative;
}

.list--has-seperator-line li + li {
    @media screen and (min-width: 768px) {
        padding-left:calc(25rem/16);
    }
}