

.nav-button:not(.nav-button__menu) {
    color: var(--color-text-default);
    text-transform: uppercase;
    width:100%;
    height:calc(82rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    border-top:2px solid #fff;
    @media screen and (max-width: 767px) {
        border-left:1px solid #fff;
    }
    @media (max-width: 1199px) and (min-width: 768px) {
        padding-top:calc(15rem/16);
        padding-bottom:calc(15rem/16);
    }
}

.nav-button--big {
    grid-column: 1 / span 2;
}

.nav-button button {
    width: 100%;
    height: 100%;
}

.nav-button:not(.nav-button--booking):not(.nav-button__menu) {
    background-color: var(--color-grey-light);
    transition:all 0.2s ease;
}

.nav-button:not(.nav-button--booking):not(.nav-button__menu):hover {
    @media screen and (min-width: 768px) {
        background-color: var(--color-middlegrey);
    }
}

.nav-button.nav-button--booking {
    color: #fff;
    background-color:var(--color-booking);
    width: 100%;

    @media (max-width: 767px) {
        width: calc(70rem/16);
        height: calc(70rem/16);
        border: none;
    }
}

.nav-button.nav-button--booking:hover {
    @media screen and (min-width: 768px) {
        transition:all 0.2s ease;
        background-color:var(--color-booking-hover);
    }

}

.nav-button.nav-button--booking .nav-button__icon {
    @media (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}

.nav-button .nav-button__text {
    @media (max-width: 767px) {
        font-size: calc(8rem/16);
        line-height: calc(10/8);
    }
}

.nav-button.nav-button__menu {
    text-align:center;
    height:auto;
    color:#fff;
    text-transform: uppercase;
    width:100%;
    @media screen and (max-width: 767px) {
        transition: all 0.2s ease;
        height:calc(69rem/16);
        padding:0px;
        display:flex;
        justify-content:flex-end;
        align-items:center;
        position:absolute;
        right:0;
        top:0;
        background-color:#fff;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding-top:calc(20rem/16);
    }

}

.nav-button__menu > * > button {
    width: 100%;
    height: calc(80rem/16);
    align-items: center;
    justify-content: center;
    gap: calc(8rem/16);
    background-color: var(--color-grey-light);
    transition:all 0.2s ease;

    &:hover {
        background-color: var(--color-middlegrey);
    }

    @media (max-width: 767px) {
        background-color: #fff;
        width: calc(70rem/16);
        height: calc(70rem/16);
    }
}

.nav-button__menu > * > button > .menu-icon__wrapper {
    width: fit-content;
}


.nav-button__menu > * > button > .menu-icon__wrapper + .nav-button__text {
    color: var(--color-text-default);
}

.is-open .nav-button > * > button {
    background-color: var(--color-primary);
}

.is-open .nav-button__menu > * > button .menu-icon__wrapper + .nav-button__text{
    color: #fff;
}

.is-open .nav-button.nav-button__menu {
    @media screen and (max-width: 767px) {
        background-color:var(--color-primary);
        height:calc(70rem/16);
    }
}
.nav-button__menu--text {
    color:#fff;
    transform:rotate(-90deg);
    text-transform: uppercase;
    font-family:var(--font-default-bold);
    letter-spacing: 0.2px;
    height:calc(50rem/16);
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:calc(14rem/16);
    @media (max-width: 1400px) and (min-width: 768px) {
        display:none;
    }
}

.nav-button__text {
    font-size:calc(10rem/16);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing:1.1px;

}
.nav-button__icon{
    font-size:calc(30rem/16);
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size:calc(22rem/16);
    }
}

.nav-button.active {
    background-color: var(--color-primary-light) !important;
    color:var(--color-primary);
    transition:all 0.2s ease;
}