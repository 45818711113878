
.interactive-map .leaflet-control-zoom-in,
.interactive-map .leaflet-control-zoom-out,
.interactive-map .leaflet-control-zoom-in:hover,
.interactive-map .leaflet-control-zoom-out:hover {
    width: calc(45rem / 16) !important;
    height: calc(45rem / 16) !important;
    background-color: #fff;
    border: 1px solid var(--color-primary);
    font-size: calc(25rem / 16);
    position: absolute;
    top: calc(40rem / 16);
    left: calc(40rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    font-family: var(--font-default);
    border-bottom: 1px solid var(--color-primary) !important;
    z-index: 2;
    @media screen and (max-width: 767px) {
        top: calc(10rem / 16);
        left: calc(10rem / 16);
    }
}

.interactive-map .leaflet-control-zoom-out, .interactive-map .leaflet-control-zoom-out:hover {
    top: calc(90rem / 16);
    border-bottom: 1px solid var(--color-primary) !important;
    @media screen and (max-width: 767px) {
        top: calc(65rem / 16);
    }
}

.interactive-map .leaflet-popup-content-wrapper, .interactive-map .leaflet-popup-tip {
    border-radius: 0;
}

.interactive-map .leaflet-popup-content {
    padding: 0;
}

.interactive-map__info-box-title {
    font-size: calc(26rem/16);
    font-family: var(--font-headline-italic);
    padding-bottom: calc(10rem/16);
}
.interactive-map__info-box-body {
    padding-top: calc(20rem / 16);
    font-size: calc(14rem / 16);
    font-family: var(--font-default);
}
.interactive-map__info-box-index {
    background-color:var(--color-primary);
    color:#fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size:calc(18rem/16);
    position:absolute;
    top:0;
    font-family:var(--font-default-bold);
    right:0;
    height:calc(30rem/16);
    width:calc(45rem/16);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:calc(3rem/16);
}
.interactive-map__info-box-index:before {
    content: '';
    display: block;
    position: absolute;
    left: -3px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 30px 0;
    border-color: transparent var(--color-primary) transparent transparent;
}
.interactive-map__info-box-index:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 45px 10px 0;
    border-color: transparent var(--color-primary) transparent transparent;
}
.interactive-map__additional-button {
    width: calc(45rem / 16);
    height: calc(45rem / 16);
    background-color: #fff;
    border: 1px solid var(--color-primary);
    font-size: calc(20rem / 16);
    position: absolute;
    left: calc(43rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);
    top: calc(143rem/16);
    font-family: var(--font-default);
    z-index: 2;
    @media screen and (max-width: 767px) {
        top: calc(122rem/16);
        left: calc(13rem / 16);
    }
}
.interactive-map__additional-button + .interactive-map__additional-button {
    top: calc(193rem/16);
    @media screen and (max-width: 767px) {
        top: calc(176rem/16);
    }
}
.interactive-map.hide-markers .leaflet-marker-pane {
    display:none;
}
.interactive-map__poi-color {
    fill: var(--color-primary);
}
.interactive-map {
    background-color: var(--color-overlay);
    z-index: 1;
}
.interactive-map.is-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.fullscreen-button.opened {
    z-index: 99;
    bottom:0;
    left:0;
    position:fixed;
    width:auto;
}
.leaflet-control-fullscreen {

}
@media screen and (max-width: 767px) {
    .interactive-map.overlay-open .leaflet-control-container {
        display: none;
    }
    .interactive-map.overlay-open {
        z-index: 9;
    }
}