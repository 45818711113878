
.link-teaser {
    height:100%;
    color: var(--color-primary);
    background: linear-gradient(45deg, var(--color-grey-light) 0%, #FFFFFF 100%);
}

.link-teaser__content {
    padding: calc(40rem/16) calc(48rem/16);
    height:100%;
    font-size:calc(16rem/16);
    @media screen and (max-width: 1199px) {
        padding:calc(15rem/16);
    }
}
.link-teaser ul {
    height:100%;
}

.link-teaser li {
    width:100%;
    align-items: center;
    display: flex;
    z-index: 1;
    position:relative;
}

.link-teaser li a {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.link-teaser li:before {
    content:'';
    position:absolute;
    width:100%;
    height:1px;
    background-color: #dcdcdc;
    left:0;
    right:0;
    display:block;
    bottom:0;
    transform-origin: left;
    transition: all 0.3s ease-out;
    z-index: 0;
}

.link-teaser li:last-child:before {
    content: none;
}

.link-teaser li:hover:before {
    transform: scaleX(1.1)
}
.link-teaser li a:focus-visible{
    background-color: #FFFFFF;
    color: var(--color-primary-dark);
}

.link-teaser li:hover a {
    color: var(--color-primary-dark);
}

.link-teaser__item-icon {
    font-size: calc(8rem/16);
}