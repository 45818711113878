.contwise-map {
    position: relative;
    z-index: 1;
}
.is-fullscreen.contwise-map {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
.contwise-map__controls {
    z-index: 999;
    position: absolute;
    top: 0;
}
.contwise-map .fullscreen-button {
    z-index: 9999;
}
.contwise-map .leaflet-marker-shadow {
    display: none !important;
}
.contwise-map .leaflet-popup-content-wrapper {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
}
.contwise-map .leaflet-popup-content {
    padding: 0;
    margin: 0;
}
.contwise-map .leaflet-marker-icon.leaflet-div-icon {
    background: none;
    border: 0;
}
.contwise-map .nefos-clusterLabelContainer {
    box-shadow: none;
    border: none;
    background: none !important;
}
.contwise-map .nefos-clusterLabelItem {
    position: relative;
}
.contwise-map .nefos-clusterLabelItemText {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: calc(20rem / 16);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}
/*.contwise-map .nefos-clusterLabelItemImage {display: none;}*/