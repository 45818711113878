

.status {
    border-radius:50%;
    color:#fff;
    height:calc(26rem/16);
    width:calc(26rem/16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.status .icon {
    font-size: calc(10rem/16);
    margin-left:0px;
}

.status--open {
    background-color:#6D9A27;
}

.status--closed {
    background-color:#E74011;
}


.status--preparation {
    background-color: #FFCE54;
    position:relative;
}

.status--preparation:before {
    content:'';
    display:block;
    background: transparent;
    position: absolute;
    margin: 0 auto;
    width: calc(10rem/16);
    height: calc(10rem/16);
    border-radius: 20px;
    box-shadow: 0 0 0 rgba(255, 206, 84, 0.94);
    animation: pulse 2s infinite;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 0;
    transform: translateY(-50%);
}


@keyframes pulse {
    0% {
        webkit-appearance: none;
        -webkit-box-shadow: 0 0 0 0 rgba(255, 206, 84, 0.5);
        -moz-box-shadow: 0 0 0 0 rgba(255, 206, 84, 0.5);
        box-shadow: 0 0 0 0 rgba(255, 206, 84, 0.5);
    }
    70% {
        webkit-appearance: none;
        -webkit-box-shadow: 0 0 0 0 rgba(255, 206, 84, 0.5);
        -moz-box-shadow: 0 0 0 80px rgba(114, 7, 0, 0);
        box-shadow: 0 0 0 20px rgba(114, 7, 0, 0);
    }
    100% {
        webkit-appearance: none;
        -webkit-box-shadow: 0 0 0 0 rgba(255, 206, 84, 0.5);
        -moz-box-shadow: 0 0 0 0 rgba(114, 7, 0, 0);
        box-shadow: 0 0 0 0 rgba(114, 7, 0, 0);
    }
}