
.link-box__wrapper {
    border-bottom: 1px solid #000;
    margin-bottom:calc(45rem/16);
}

.link-box__wrapper ul {
    display:flex;
}
.link-box {
    height:calc(108rem/16);
    width:calc(124rem/16);
    display:inline-block;
    transition: all 0.2s ease;
    position:relative;
    cursor:pointer;
}

.link-box a {
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    padding: 25px 0px 14px;
}
.link-box a:focus-visible{
    border: #000 2px solid;
}

.link-box__icon {
    display: block;
    font-size:calc(40rem/16);

}

.link-box__text {
    margin-top:auto;
    display: block;
    color:var(--color-dark);
    text-transform: uppercase;
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
    letter-spacing: calc(0.4rem/16);
    text-align:center;
    padding: calc(3rem/16) calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(9rem/16);
        letter-spacing:0;
    }
}

.link-box.active {
    background-image:url("/static/img/backgrounds/bg-tabbing.svg");
    color:#fff;
    background-size:cover;
}
.link-box.active .link-box__text {
    color:#fff;
}


.link-box:before {
    content:'';
    display:block;
    position:absolute;
    width:100%;
    height:100%;
    background-image:url("/static/img/backgrounds/bg-tabbing-border.svg");
    background-size:cover;
    opacity:0;
    transition: all 0.2s ease;
}

.link-box:hover:before {
    color:#000;
    opacity:1;
}