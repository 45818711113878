.leaflet-container a {
    color: var(--color-primary);
    padding-left:0;
}
.leaflet-container .link {
    padding-left:0;
}
.leaflet-container a.leaflet-popup-close-button {
    font-size: calc(35rem/16);
    height: calc(45rem/16);
    width: calc(45rem/16);
    padding: calc(10rem/16);
    font-family: var(--font-headline);
    z-index: 1;
}
.leaflet-popup-content {
    padding: calc(20rem/16);
}
.leaflet-control-fullscreen {
    margin:0 !important;
    cursor:pointer;
    border: none !important;
    border-radius: 0 !important;
}
.leaflet-control-fullscreen .leaflet-control-fullscreen-button {
    background-color:transparent !important;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
    color:#fff;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leaflet-control-fullscreen .leaflet-control-fullscreen-button:before {
    content: var(--icon-fullscreen);
    color:#fff;
}
.leaflet-left .leaflet-control {
    margin-left: 0 !important;
}
.leaflet-top .leaflet-control {
    margin-top: 0 !important;
}
