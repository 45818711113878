.lg-backdrop {
    background-color: #F1F1F1;
}

.lg-toolbar, .lg-actions .lg-prev, .lg-actions .lg-next {
    background-color:transparent;
    color:var(--color-primary);
}

.lg-sub-html {
    background-color:transparent;
    font-size:calc(15rem/16);
    font-family:var(--font-default);
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16);
    }
}
.lg-actions .lg-prev, .lg-actions .lg-next {
    font-size:calc(18rem/16);
    padding:calc(15rem/16) calc(20rem/16);
    border:1px solid var(--color-primary);
    @media screen and (max-width: 767px) {
        top:40%;
    }
}

.lg-toolbar .lg-close, .lg-actions .lg-next, .lg-actions .lg-prev {
    color:var(--color-primary);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}
.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color:var(--color-primary-light);
}
.lg-toolbar .lg-close:hover {
    color:var(--color-primary);
}

.lg-toolbar .lg-close {
    right: 20px;
    top: 20px;
    background-color: #fff;
    width: 4.375rem;
    height: 4.375rem;
    z-index: 9;
    box-shadow: none;
    border: none;
    color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    transition: all .2s ease;
    margin-top:calc(20rem/16);
    margin-right: calc(10rem/16);
}
.lg-toolbar .lg-close:after {
    content: var(--icon-closed);
}

.lg-actions .lg-next:before {
    content: var(--icon-arrow-next);
}


.lg-actions .lg-prev:after {
    content:var(--icon-arrow-back);
}

.lightgallery-play__btn {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.73);
    opacity: 1;
    z-index: 1;
    border: none;
    color: var(--color-text-default);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(24rem/16);
}

.lightbox--presse .lg-item {
    max-height: 70vh;
    @media screen and (max-width: 767px) {
        max-height: calc(200rem/16);
    }
}

.lightbox--presse .lg-image {
    background-color: #fff;
    max-height: 50vh;
    margin-top: 10vh;
    @media screen and (max-width: 767px) {
        margin-top: 30px;
        max-height: calc(170rem/16);
    }
}

.lightbox--iframe.lg-outer .lg-video{
   padding-bottom:60%;
    background-color:#fff;
}