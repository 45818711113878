
.noUi-connect {
    background-color:var(--color-primary);
}

.noUi-horizontal {
    height:calc(6rem/16);
}

.noUi-horizontal .noUi-handle {
    border-radius: 50%;
    height:calc(23rem/16);
    width:calc(23rem/16);
    margin-top: -4px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
}

.noUi-horizontal .noUi-handle:before, .noUi-horizontal .noUi-handle:after {
    display: none;
}

.noUi-target {
    border-radius:0px;
    box-shadow:none;
    background-color:var(--color-middlegrey);
    border:none;
}

.noUi-connects {
    border-radius:0px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
    right: -11px;
}