
.nav-item {
    color:#000;
}

.nav-item.active {
    color:var(--color-primary);
}
.navbar__detail-first-level .nav-item__link{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    width: 100%;
    @media screen and (max-width: 767px) {
        justify-content: space-between;
    }

}
.navbar__detail-first-level .navbar__detail-container > ul .nav-item__link {
    font-family:var(--font-default-bold);
    font-size:calc(19rem/16);
    text-align:right;
    line-height: 1;
    padding-top: calc(20rem/16);
    padding-bottom:calc(20rem/16);
    @media screen and (max-width: 1400px) {
        font-size:calc(15rem/16);
    }
    @media screen and (max-width: 1199px) {
        font-size:calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        text-align:left;
    }
}

.navbar__detail-second-level .navbar__detail-container  > ul .nav-item__link {
    text-align:left;
    font-family:var(--font-default);
    font-size:calc(16rem/16);
    line-height: 1;
    padding-top: calc(15rem/16);
    padding-bottom:calc(15rem/16);
    text-transform: none;
    @media screen and (min-width: 768px) {
        min-height: calc(50rem/16);
    }
}
.navbar__detail-second-level .nav-item__link{
    display:flex;
    align-items:center;
    justify-content: space-between;
    width: 100%;
}

.nav-item__link .icon {
    font-size: calc(18rem/16);
    color:var(--color-grey);
    padding-left:calc(19rem/16);
    @media screen and (max-width: 1199px) {
        font-size:calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.nav-item.active > .nav-item__link .icon {
    color:var(--color-primary);
}
.nav-item__link-arrow {
    @media screen and (max-width: 767px) {
        display: inline-block;
        color: #ffffff;
        background-color: var(--color-primary);
        outline: 1px solid var(--color-primary);
        padding: 1rem 1.25rem;
        margin: -1rem -1.25rem;
    }

    @media screen and (min-width: 768px) {
        position: absolute;
        right: calc(20rem/16);
    }
}
.navbar__detail-second-level .nav-item__link-arrow {
    @media screen and (max-width: 767px) {
        margin-right: -.5rem;
        margin-top: -.75rem;
        margin-bottom: -.75rem;
    }
}
@media screen and (max-width: 767px) {
    .nav-item__link-arrow > .icon.icon.icon-plus-bold {
        padding: 0;
        color: inherit;
    }
}



