@media screen and (max-width: 767px) {
    .teaser-slider {
        margin-bottom: 7rem;
    }
}
.teaser-slider .slick-dots {
    display: none !important;
}
.teaser-slider .slick-arrow {
    position: absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    border:1px solid var(--color-primary);
    justify-content:center;
    align-items:center;
    font-size:calc(17rem/16);
    color:var(--color-primary);
    height:calc(45rem/16);
    width:auto;
    padding:0 1.5rem;
    display:flex;
    transition:all 0.2s ease;
    outline: 0;
    z-index:5;
    @media screen and (min-width: 1641px) {
        left:-6.5rem;
        background:transparent;
    }
    @media screen and (max-width: 767px) {
        left:50%;
        margin-left:-6rem;
        top:100%;
        transform:translateY(1.25rem);
    }
}
.teaser-slider .slick-arrow.slick-next {
    left:auto;
    right:0;
    @media screen and (min-width: 1641px) {
        left:auto;
        right:-6.5rem;
    }
    @media screen and (max-width: 767px) {
        left:auto;
        right:50%;
        margin-right:-6rem;
        top:100%;
        transform:translateY(1.25rem);
    }
}
.teaser-slider .slick-arrow:hover {
    background-color:var(--color-primary);
    color:#fff;
}
.teaser-slider .slick-arrow:hover .icon {
    animation: moveArrow 2s forwards infinite;

}

@keyframes moveArrow {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(5px, 0);
    }
    60% {
        transform: translate(-5px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}