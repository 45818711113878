.thumb-slider__arrow {
    border:1px solid var(--color-primary);
    justify-content:center;
    align-items:center;
    font-size:calc(17rem/16);
    color:var(--color-primary);
    height:calc(45rem/16);
    width:100%;
    display:flex;
    background-color:#fff;
    transition:all 0.2s ease;
}

.thumb-slider__arrow + .thumb-slider__arrow {
    margin-top:calc(10rem/16);
}




.thumb-slider__arrow:hover {
    background-color:var(--color-primary);
    color:#fff;
}
.thumb-slider__arrow:hover .icon {
    animation: moveArrow 2s forwards infinite;

}

@keyframes moveArrow {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(5px, 0);
    }
    60% {
        transform: translate(-5px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

/* new tumb-slider style */
.thumb-slider--new .thumb-slider__badge {
    position: absolute;
    top: calc(10rem/16);
    right: calc(10rem/16);
    z-index: 1;
    background-color: rgba(250, 250, 250, 0.5);
    backdrop-filter: blur(calc(8rem/16));
    color: var(--color-dark);
    padding: calc(8rem/16) calc(10rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    display: flex;
    align-items: center;
    gap: calc(4rem/16);
    height: fit-content;
}