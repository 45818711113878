.regions__content-img {
    position:absolute;
    top:0;
    left:0;
    right:0;
    height: 100%;
    margin: 0 auto;
    transform: translateX(1.2vw);
    @media screen and (max-width: 1199px) {
        position:relative;
        width: 53vw;
    }
    @media screen and (min-width:1920px) {
        transform:translateX(25px);
    }
}

.regions__background-img {
    @media screen and (max-width: 1199px) {
        display:none !important;
    }
}

.regions__content-img-arrival {
    transform: translateX(6.2vw);
    @media screen and (max-width: 1199px) {
        width:65vw;
    }
    @media screen and (min-width:1920px) {
        transform:translateX(112px)
    }
}


.regions__arrival-standalone .regions__content-img-arrival {
    transform: translateX(0px);
    width:100%;
    height:auto;
    position:relative;
}