
.readmore-button {
    background-color: var(--color-darkgrey);
    position: absolute;
    bottom: 0;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: calc(32rem / 16);
    transition: all 0.2s ease;

}

.readmore-button:hover {
    color: #fff;
    background-color: #000;
    transition: all 0.2s ease;
}

.readmore-button:before {
    position: absolute;
    transition: all 0.2s ease;
    content: '';
    top: -7px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 0 0 60px;
    border-color: transparent transparent transparent var(--color-darkgrey);
}

.readmore-button:hover:before, .readmore-button:hover:after {
    border-color: transparent transparent transparent #000;
}

.readmore-button:after {
    position: absolute;
    transition: all 0.2s ease;
    content: '';
    right: -9px;
    width: 0;
    height: 0;
    bottom: 0;
    border-style: solid;
    border-width: 60px 0 0 9px;
    border-color: transparent transparent transparent var(--color-darkgrey);

}