
.number-spinner {
    border-radius: calc(4rem/16);
    margin-bottom:calc(10rem/16);
}

.number-spinner__input,
.input-group .number-spinner__input {
    padding:0;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    font-size:calc(28rem/16);
    border:none;
    font-family:var(--font-default-bold);
    width:100%;
}
.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-spinner .number-spinner__button {
    color:var(--color-primary);
    border:1px solid var(--color-primary);
    border-radius: 50%;
    height:calc(50rem/16);
    width:calc(50rem/16);
    background: #fff;
    padding:0 calc(10rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
}

.number-spinner .btn-icon__icon {
    font-size:calc(30rem/16);
    line-height: 1;
}
.number-spinner label {
    font-size:calc(16rem/16);
}