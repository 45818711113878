
.download-link {
    font-size:calc(12rem/16);
    color:var(--color-primary);
    font-family: var(--font-default-bold);
    line-height:calc(17/12);
    position:relative;
    text-transform: uppercase;
    position:relative;
    padding-top:calc(14rem/16);
}
.download-link__text {
    position:relative;
    z-index: 1;
    padding-left:calc(10rem/16);
    transition:all 0.2s ease-out;
    letter-spacing:1px;

}
.download-link__text:before {
    content:'';
    display:block;
    position:absolute;
    bottom:-4px;
    right:0;
    background-color:var(--color-primary-light);
    width: 75%;
    height:calc(26rem/16);
    z-index: -1;
    transition:all 0.2s ease-out;
}

.download-link:hover .download-link__text {
    color:var(--color-primary-dark);
}
.download-link:hover .download-link__text:before {
    width:100%;
}

.download-link__icon {
    font-size:calc(50rem/16);
    padding-left:calc(11rem/16);
    padding-right:calc(11rem/16);
    z-index: 1;
}

.download-link__infos {
    font-size:calc(12rem/16);
    color:var(--color-primary-light);
    padding-left:calc(12rem/16);
    font-family:var(--font-default);
    text-align: right;
    display: inline-block;
    position: absolute;
    bottom: calc(-23rem/16);
    right: 0;

}