.tour-map {
    position: relative;
    z-index: 1;
}

.tour-map__controls {
    z-index: 999;
    position: absolute;
    top: 0;
}

.tour-map .fullscreen-button {
    z-index: 9999;
}

.tour-map .leaflet-control-attribution img,
.contwise-map .leaflet-control-attribution img {
    margin-left: 0!important;
    margin-right: 0!important;
    height: 50px!important;
    display: block;
    @media screen and (min-width: 768px){
        height: 118px!important;
    }
}