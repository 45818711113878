

/* fix content width inside hidden tabs */
.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: block;     /* undo display:none          */
    height: 0;          /* height:0 is also invisible */
    overflow-y: hidden; /* no-overflow                */
    overflow-x:hidden;
}
.tab-content > .active,
.pill-content > .active {
    height: auto;
}


