
.parsley-errors-list {
    font-size:calc(11rem/16);
    list-style: none;
    padding-left: 0;
    color:var(--color-danger);
    margin: 0;
    text-align: right;
    position: absolute;
    left: 0;
    text-transform:initial;
    right: 0;
}

.has-error label {
    color: var(--color-danger);
}

.parsley-errors-list li {
    list-style:none;
    text-align:right;
}
