
.facility-list {

}

.facility-list__count {
    height: calc(70rem/16);
    @media screen and (max-width: 767px) {
        height:auto;
    }
}

.facility-list__item {
    padding-top: 30px;
    padding-bottom: 50px;
    height:calc(200rem/16);
    @media screen and (max-width: 767px) {
        height:auto;
        padding:10px;
    }
}

.facility-list__item-text--big {
    font-size: calc(78rem/16);
    @media screen and (max-width: 1920px) and (min-width: 767px) {
        font-size: 3.2vw;
    }
    @media screen and (max-width: 767px) {
        font-size:calc(28rem/16);
    }
}
.facility-list__item-text--small {
    font-size: calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
    }
}

.facility-list__item-text {
    font-size: calc(18rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
        hyphens: auto;
    }
}
.facility-list__item-link:hover {
    color: var(--color-primary);
}