.teaser-row__wrapper {
    position:relative;
    padding-bottom:calc(80rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom:calc(50rem/16);
        padding-top:calc(40rem/16);
    }
}



.teaser-row__title {
    @media screen and (max-width:767px) {
        padding-left:calc(15rem/16);
        padding-right:calc(15rem/16);
    }
}

.teaser-row__footer {
    padding-right:calc(70rem/16);
    @media screen and (max-width: 767px) {
        padding-left:calc(15rem/16);
        padding-right:calc(15rem/16);
    }
}

.teaser-row:before {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% - 80px);
    top: 30px;
    bottom:0;
    background-color:var(--color-primary-light);
}
.teaser-row--has-triangle {
    position:relative;
}
.teaser-row--has-triangle:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: -100px;
    border-style: solid;
    left: 0;
    z-index: -1;
    border-width: 130px 0 0 calc(100vw - 166px);
    border-color: transparent transparent transparent var(--color-primary-light);
    @media screen and (max-width: 767px) {
        border-width: 73px 0 0 1920px;
        top: -73px;
    }
}

.teaser-row--has-triangle:after {
    position: absolute;
    content: "";
    z-index: -1;
    width: 0;
    height: 0;
    right:0;
    bottom: -80px;
    border-style: solid;
    left: 0;
    border-width: 0 calc(100vw - 166px) 130px 0;
    border-color: transparent  var(--color-primary-light) transparent transparent;
    @media screen and (max-width: 767px) {
        border-width: 134px 0 0 1920px;
        left: -200%;
        right: initial;
        z-index: -1;
        top: 100%;
        margin-top: -5px;
        border-color: var(--color-primary-light) transparent transparent transparent;
    }
}

.teaser-row__content {
    z-index: 2;
}

.teaser-row__teaser-wrapper {
    max-width: calc(334rem/16);
}

/* new teaser-row styling */
.teaser-row.teaser-row--new {
    &:before {
        top: 50%;
        height: calc(100% - 110rem/16 * 2);
        background-color: var(--color-grey-light);
        transform: translateY(-50%);
    }
}

.teaser-row.teaser-row--new.no-bg:before {
    display: none;
}

.teaser-row--new .slick-list {
    margin: 0 calc(-6rem/16);
}

.teaser-row--new .slick-slide > div {
    padding: 0 calc(6rem/16);
}