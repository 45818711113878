
.live-infos__wrapper {
    display:block;
    position:absolute;
    right:150px;
    top:100px;
    z-index: 2;
    @media screen and (max-width:1199px)  and (min-width:767px)  and (orientation: landscape) {
        right:calc(100rem/16);
        top:calc(30rem/16);
    }
}


.live-info__item {
    height:calc(120rem/16);
    width:calc(126rem/16);
    color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    line-height:calc(18/12);
    font-size:calc(12rem/16);
    cursor:pointer;
    position:relative;
    letter-spacing:1px;
    @media screen and (min-width: 767px) {
        opacity:0;
        position:relative;
    }
}

.start-animation .live-info__item:first-of-type {
    animation: liveInfosInitOne 0.3s 0.1s ease-out forwards;
}

.start-animation .live-info__item:nth-of-type(2) {
    animation: liveInfosInitOne 0.3s 0.2s ease-out forwards;
}

.start-animation .live-info__item:nth-of-type(3) {
    animation: liveInfosInitOne 0.3s 0.3s ease-out forwards;
}

.start-animation .live-info__item:nth-of-type(4) {
    animation: liveInfosInitOne 0.3s 0.4s ease-out forwards;
}

@keyframes liveInfosInitOne {
    from{
        opacity:0;
        transform:  scale(0.3);
    }
    to {
        opacity:1;
        transform: scale(1);
    }
}


.live-info__item-text {
    font-size:calc(11rem/16);
}
.live-info__item-icon {
    font-size:calc(46rem/16);
    display:block;
    margin-bottom:calc(10rem/16);
    margin-left: calc(-12rem/16);
}
.live-info__item--panorama .live-info__item-icon {
    font-size:calc(30rem/16);
    margin: 0.6rem auto;
}

.live-info__svg {
    height:calc(120rem/16);
    width:calc(126rem/16);
    position:absolute;
}
.live-info__hovered {
    position:absolute;
    height:232px;
    width:240px;
    z-index: 2;
    background-size:cover;
    top: -50%;
    left: -50%;
    transform:scale(0);
    transform-origin:center;
    animation: liveInfoScaleOut .2s forwards;

}

@media screen and (min-width: 767px) {

    .live-info__hovered-icon {
        opacity:0;
        position:absolute;
        transform-origin: center;
        transform: translate(110%,100%);
        transition: transform 0.9s cubic-bezier(.4,0,0,1) 0.3s, opacity 0.8s cubic-bezier(.4,0,0,1) 20ms;
    }
    .live-info__hovered-text {
        //opacity:0;
        padding:0px 20px 20px;
        text-align:left;
        position:relative;
        text-transform: none;
        opacity:0;
        transform:scale(0);
        //transform: scale(0);
        //transition: transform .4s cubic-bezier(.4,0,0,1),opacity 1s cubic-bezier(.4,0,0,1) 150ms;
    }

}

.live-info__item--webcams .live-info__hovered-text, .live-info__item--activities .live-info__hovered-text, .live-info__item--panorama .live-info__hovered-text {
    padding: 0px 20px 40px;
}

.live-info__item:hover .live-info__hovered {
    animation: liveInfoScale .45s forwards;
}

@keyframes liveInfoScale {
    0% {
        opacity:0;
        transform:scale(0);
    }
    60% {
        opacity:1;
        transform:scale(1.03);
    }
    100% {
        opacity:1;
        transform:scale(1);
    }
}

@keyframes liveInfoScaleText {
    0% {
        opacity:0;
        transform:scale(0.6);
    }
    60% {
        opacity:1;
        transform:scale(1.02);
    }
    100% {
        opacity:1;
        transform:scale(1);
    }
}


@keyframes liveInfoScaleOut {
    0% {
        opacity:1;
        transform:scale(1);
    }
    80% {
        opacity:1;
    }
    100% {
        opacity:0;
        transform:scale(0);
    }
}

.live-info__item:hover {
    transition-delay:0s !important;
    z-index: 33;
}

.live-info__item:hover .live-info__hovered-icon {
    opacity: 1;
    transition-delay: 0.1s, .15s;
}

.live-info__item--weather {
    float:left;
    /*padding-top:calc(20rem/16);*/
}
.live-info__item--facilities {
    padding-top:calc(20rem/16);
}
.live-info__item--webcams {
    float:left;
}
.live-info__item--panorama {
    float:left;
}


.live-info__item--weather:hover .live-info__hovered-icon {transform:translate(78%,-12%);}
.live-info__item--facilities:hover .live-info__hovered-icon {transform: translate(88%,-5%);}
.live-info__item--activities:hover .live-info__hovered-icon {transform: translate(90%,-27%);}
.live-info__item--webcams:hover .live-info__hovered-icon {transform: translateX(61%) translateY(-49%) rotate(-20deg);}
.live-info__item--panorama:hover .live-info__hovered-icon {transform: translateX(124%) translateY(-39%) rotate(-11deg);}


.live-info__item--weather .live-info__hovered-icon {width:calc(138rem/16);}
.live-info__item--facilities .live-info__hovered-icon {width: calc(152rem/16);}
.live-info__item--webcams .live-info__hovered-icon {width: calc(160rem/16);}
.live-info__item--activities .live-info__hovered-icon {width: calc(170rem/16);}
.live-info__item--panorama .live-info__hovered-icon {width: 6.625rem;}


.live-info__item:hover .live-info__hovered-text {
    //opacity:1;
    //transition-delay: 0.1s,.15s;
    //transform: scale(1);
    animation: liveInfoScaleText 1s .2s forwards;
}

.live-infos__affix-wrapper {
    position:fixed;
    top:0;
    z-index: 5;
    right:0;
    @media screen and (max-width: 767px) {
        width:100%;
        left:0;
        display:block;
        right:0;
        top:initial;
        bottom:0;
    }
  //  display:block;
}
.live-infos--affix {
    @media screen and (max-width: 767px) {
        display:flex;
        align-items: stretch;
        flex-direction: row;
    }
}
.live-infos--affix li {
    flex: 1 1 0;
    width: 0;
}
.live-infos__affix-wrapper .overlay--dark {
    background-image: none;
    background-color: var(--color-middlegrey);
    color: #000;
    padding-top: calc(30rem/16);
}

.live-info__affix-item-text {
    font-family: var(--font-default);
    font-size: calc(8rem/16);
    line-height: calc(10/8);
    letter-spacing: 1.1px;
    text-transform: uppercase;
    hyphens: auto;
    margin-top: calc(8rem/16);
}

.live-info__affix-item {
    background-color: var(--color-grey-light);
    color: #000;
    width:calc(54rem/16);
    height:calc(70rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-right:1px solid #fff;
    transition:all 0.3s ease;
    @media screen and (max-width: 767px) {
        width: 100%;
        border-right: 1px solid #cdcdcd;
    }
}

.live-info__affix-item.active {
    background-color: var(--color-middlegrey);
}

.live-infos--affix li {
    display:inline-flex;
    flex-grow:1;

}

a.live-info__affix-item:hover,
a.live-info__affix-item:focus-visible {
    @media screen and (min-width: 767px) {
        background-color:var(--color-primary-middle-light);
        color:#fff;
    }
}

.live-info__affix-item-wide {
    width:calc(84rem/16);
}

@media screen and (min-width: 768px) {

    .live-infos--expanded:before {
        content: "";
        background-image: url("/static/img/backgrounds/live-bkg-shadow.svg");
        position: absolute;
        background-size: cover;
        width: 150%;
        height: 150%;
        z-index: -1;
        visibility: visible;
        display: block;
        left: 50%;
        top: 50%;
        opacity: 0;
        transition:opacity 0.2s 0.2s ease-out;
        transform: translateX(-50%) translateY(-50%);

    }



    .start-animation .live-infos--expanded:before {
        opacity:1;
    }

    .live-infos--affix.js-live-infos--affix {
        opacity:0;
        transform:translateY(-200px);
        transition:all 0.3s ease-out;
    }

    .live-infos--affix.is-affix {
        opacity:1;
        transform:translateY(0);

    }

    .live-infos--affix.js-live-infos--affix li {
        transform:translateY(-100%);
        transition:translateY 0.3s 0.3s ease-out;
    }

    .live-infos--affix li:nth-of-type(1) {
        transition:all 50ms 0.2s ease-out;
    }
    .live-infos--affix li:nth-of-type(2) {
        transition:all 150ms 0.2s ease-out;
    }
    .live-infos--affix li:nth-of-type(3) {
        transition:all 250ms 0.2s ease-out;
    }
    .live-infos--affix li:nth-of-type(4) {
        transition:all 350ms 0.2s ease-out;
    }
    .live-infos--affix li:nth-of-type(5) {
        transition:all 450ms 0.2s ease-out;
    }
    .live-infos--affix.is-affix li{
        transform:translateY(0px);
    }
}

.live-infos--expanded {
    opacity:1;
    visibility:visible;
    display:block;
    transform:translateY(0) translateX(0);
    transition:all 0.45s ease-out;

}

.live-infos--expanded.not-affix {
    opacity:0;
   // display: none;
    visibility:hidden;
    transform:translateY(-200px) translateX(200px);
    transition:all 0.45s ease-out;
    @media screen and (max-width: 1199px) {
        transform:translateY(-200px) translateX(0px);
    }
}


.live-info__webcam-icon--mobile {
    position: absolute;
    right: calc(-17rem/16);
    top: calc(22rem/16);
    transform: rotate(-21deg);
    z-index: 2;
    width: calc(135rem/16);
}
.live-info__panorama-icon--mobile {
    position: absolute;
    right: 20px;
    top: 5.375rem;
    transform: rotate(-16deg);
    z-index: 2;
    width: 6rem;
}
.live-info__img-text {
    line-height:2;
    display:flex;
    justify-content:flex-end;
    flex-direction: column;
    padding-left:calc(5rem/16);
}
.live-info__img-text-border:after {
    height:calc(1rem/16);
    width:calc(23rem/16);
    background-color:#fff;
    content:'';
    display:block;
    position:absolute;
    margin-left: -4px;
}

.live-info__item-countdown-text {
    font-family:var(--font-headline);
    line-height:1;
    font-size: calc(40rem/16);
    @media screen and (max-width: 767px) {
        display:block;
        font-size: calc(20rem/16);
    }
}
.live-infos__countdown-icon {
    font-size: calc(60rem/16);
    position: absolute;
    color: #fff;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    opacity:0;
    justify-content: center;
    align-items: center;

}

.start-animation .live-infos__countdown-icon {
    transform-origin: center 52%;
    animation: liveInfosInitStopWatch 0.9s 0.3s ease-out forwards;
}

@keyframes liveInfosInitStopWatch {
    from{
        opacity:0;
        transform:  scale(0.3) rotate(-360deg);
    }
    to {
        opacity:1;
        transform: scale(1) rotate(0deg);
    }
}

/* New Live info in nav-Bar */
.nav-button.nav-button--live-info {
    background: none;
    border-right: none;
    hyphens: auto;
}

.nav-button.nav-button--live-info .live-info__hovered {
    bottom: 0;
    top: unset;
    left: calc(166rem/16);
    height: calc(208rem/16);
    transform: translateX(-100%) scale(0);
    animation: liveInfoSlideOut .2s forwards;
    background-color: var(--color-middlegrey);
    z-index: -1;
}

.nav-button.nav-button--live-info:hover .live-info__hovered-text {
    opacity: 1;
    transform: scale(1);
}

.nav-button.nav-button--live-info:hover .live-info__hovered .live-info__hovered-icon {
    opacity: 1;
}

.nav-button.nav-button--live-info:hover .live-info__hovered {
    animation: liveInfoSlideIn .45s forwards;
}

.nav-button.nav-button--live-info.live-info__item--facilities {
    padding-top: 0;
}

@keyframes liveInfoSlideIn {
    0% {
        opacity:0;
        transform: translateX(-100%) scale(1);
    }
    100% {
        opacity:1;
        transform: translateX(0%) scale(1);
    }
}
@keyframes liveInfoSlideOut {
    0% {
        opacity:1;
        transform: translateX(0%) scale(1);
    }
    99% {
        opacity:0;
        transform: translateX(-100%) scale(1);
    }
    100% {
        transform: translateX(-100%) scale(0);
    }
}

.nav-button.live-info__item--facilities:hover .live-info__hovered-icon {
    transform: translate(88%,-16%);
}