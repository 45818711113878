

.custom-select__select {
    appearance: none;
    cursor:pointer;
    color:var(--color-text-default);
}

.custom-select__select::-ms-expand {
    display: none;
}

.custom-select__select option:checked {
    color: var(--color-text-default);
}
.custom-select__icon {
    position:absolute;
    right:10px;
    display: flex;
    bottom:0px;
    font-size: 8px;
    align-items: center;
    align-self: center;
    height: 100%;
    pointer-events:none;
}
.custom-select__wrapper {
    position:relative;
}

.custom-select__wrapper--border .custom-select__select {
    text-transform:uppercase;
    text-align:center;
    font-family:var(--font-default-bold);
    font-size:calc(14rem/16);
    text-align-last:center;

}
.custom-select__wrapper--border .custom-select__select:not([size]):not([multiple]) {
    @media screen and (max-width: 767px) {
        height: auto;
    }
}


.custom-select__wrapper--border:after {
    content:'';
    display:block;
    position:absolute;
    bottom:0;
    height:calc(4rem/16);
    width:100%;
    background: var(--color-primary);
}