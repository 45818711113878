
.wrapper {
    margin-left:calc(166rem/16);
    position:relative;
    overflow:hidden;
    @media screen and (max-width:767px) {
        margin-left:0px;
    }
}
.page-wrapper__main {
    padding-top:calc(80rem/16);
    @media screen and (max-width:767px) {
        padding-top:calc(30rem/16);
    }
}