
.google-map__infobox  {
    padding:0px !important;
    width:calc(570rem/16) !important;
}

.is-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}