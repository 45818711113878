


.accordion-icon--change:before {
    content:var(--icon-minus);
}

.collapsed .accordion-icon--change:before {
    content:var(--icon-plus);
}


.accordion--white-style .accordion-item__header{
    font-family:var(--font-headline-italic);
    border-bottom: 1px solid black;
    padding-bottom: calc(10rem/16);
    margin-bottom: calc(20rem/16);
    font-size:calc(22rem/16);
}


.accordion--white-style .accordion-icon--change:before {
    content:var(--icon-arrow-down);
}

.accordion--white-style .accordion-icon--change {
    font-size: 0.6rem;
    padding-right:calc(10rem/16);
    transform: scale3d(1, -1,1);
    transition:all 0.2s ease;
}

.accordion--white-style .collapsed .accordion-icon--change {
    transform: scale3d(1, 1,1);
}
