

.link-list {
    border-bottom:1px solid #000;
    list-style: none;
    display: flex;
    justify-content: center;
}

.link-list__item {
    font-size: .875rem;
    letter-spacing: 1.18px;
    color:var(--color-grey);
    text-transform: uppercase;
    font-family:var(--font-default-bold);
    display:inline-block;
    padding:calc(15rem/16) calc(54rem/16);
    position:relative;
    @media screen and (max-width: 767px) {
        padding:calc(15rem/16) calc(15rem/16);

    }
}

.link-list__item.active {
    color:#000;
}

.link-list__item:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: .0625rem;
    background-color: #fff;
    transition: background-color .3s,height .21s ease;
}
.link-list__item.active:before {
    height: .25rem;
    background-color: #000;
}