.special-slide {
    position:relative;
    background-size: cover;
    height: calc(600rem/16);
    @media screen and (max-width: 767px) {
        min-height:calc(400rem/16);
        height:auto;
    }
}
.special-slide__inner {
    width:100%;
    height:100%;
    z-index: 1;
    padding-top: calc(65rem/16);
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.special-slide__inner.special-slide__inner--gradient{
    background-image: linear-gradient(270deg,#ffffff1a 0,#585861c9 50%);
}
.special-slide__title {
    font-size: calc(50rem/16);
    letter-spacing: calc(4.5rem/16);
    line-height: calc(68/50);
    text-shadow: 0 calc(1rem/16) calc(2rem/16) rgba(0,0,0,0.2);
    font-family: var(--font-headline);
    text-transform: uppercase;
    color:#fff;
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.special-slide__subtitle {
    font-size: calc(25rem/16);
    text-shadow: 0 calc(1rem/16) calc(2rem/16) rgba(0,0,0,0.4);
    color:#fff;
    line-height: calc(28/25);
    font-family: var(--font-headline-italic);
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
    }
}
.special-slide__content {
    font-size: calc(23rem/16);
    text-shadow: 0 1px 4px rgb(0 0 0 / 89%);
    line-height: calc(32/23);
    color:#fff;
    z-index: 1;
    font-family: var(--font-default-bold);
    padding: 1rem;
    @media screen and (max-width: 1199px) {
        font-size: calc(18rem/16);
    }
}


.special-slide__logo {
    max-width: calc(388rem/16);
    margin-left: calc(140rem/16);
    margin-bottom: calc(15rem/16);
    z-index: 1;
    position:relative;
    @media screen and (max-width: 767px) {
        max-width:100%;
        margin-left:0;
        padding-left: calc(20rem/16);
        padding-right: calc(20rem/16);
    }
}
.special-slide__logo-wrapper {
    width: 100%;
}
.special-slide__logo-wrapper:before {
    content:'';
    display:block;
    background-color: #FFFFFF;
    opacity:0.5;
    width:100%;
    position:absolute;
    left:0;
    height: calc(40rem/16);
    bottom:-1px;
}
.special-slide__media {
    @media screen and (max-width: 767px) {
        max-width: calc(200rem/16);
        margin: 0 auto;
    }
}

/* new styling changes */
.special-slide.special-slide--new {
    height: auto;
}

.special-slide.special-slide--new .special-slide__inner{
    background-color: var(--color-grey-light);
    padding: 0;
    height: fit-content;
}

.special-slide.special-slide--new .special-slide__content {
    padding: calc(36rem/16) calc(16rem/16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: calc(24rem/16);
    height: 100%;

    @media (min-width: 768px) {
        gap: calc(36rem/16);
        padding: calc(56rem/16) 0;
    }
}

.special-slide.special-slide--new .special-slide__title {
    font-family: var(--font-headline-italic);
    font-size: calc(28rem/16);
    line-height: calc(34/28);
    text-shadow: none;
    text-transform: none;
    color: var(--color-dark);
    
    @media (min-width: 768px) {
        font-size: calc(40rem/16);
        line-height: calc(52/40);
    }
}

.special-slide.special-slide--new .special-slide__subtitle {
    font-family: var(--font-default);
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    text-shadow: none;
    text-transform: uppercase;
    color: var(--color-dark);
    
    @media (min-width: 768px) {
        font-size: calc(14rem/16);
        line-height: calc(21/14);
    }
}

.special-slide.special-slide--new .special-slide__subtitle + .special-slide__title {
    margin-top: calc(8rem/16);
    
    @media (min-width: 768px) {
        margin-top: calc(12rem/16);
    }
}

.special-slide.special-slide--new .wysiwyg {
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    line-height: calc(24/16);
    color: var(--color-dark);
    text-shadow: none;
}

.special-slide.special-slide--new .wysiwyg ul:not(.list-unstyled):not(.slick-dots):not(.button-style-icon):not(.parsley-errors-list) li:before {
    content: '●';
}

.special-slide.special-slide--new .special-slide__link {
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    line-height: calc(12/12);
    letter-spacing: calc(1rem/16);
    color: var(--color-primary);
    text-transform: uppercase;
    text-shadow: none;
    display: flex;
    align-items: center;
    gap: calc(10rem/16);
    transition: color 0.2s ease;

    &:hover {
        color: var(--color-primary-dark);
    }
}

.special-slide.special-slide--new .special-slide__media {
    max-width: 100%;
    width: 100%;
}

.special-slide.special-slide--new .special-slide__logo-wrapper:before {
    content: none;
}