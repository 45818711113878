
.menu-icon__wrapper {
    position:relative;
    overflow:hidden;
    display:block;
}

.menu-icon {
    display: block;
    position: relative;
    width: calc(24rem/16);
    height: calc(18rem/16);
}

.menu-icon__line {
    position: absolute;
    left: 0;
    background-color: var(--color-text-default);
    height: calc(2rem/16);
    width: calc(24rem/16);
    pointer-events: none;
}

.menu-icon__line-1, .menu-icon__line-3 {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.menu-icon__line-1 {
    top: 0;
}

.menu-icon__line-2 {
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: calc(3rem/16);
    transition: transform .2s ease-in-out;

    &:before {
        content: '';
        position: absolute;
        width: calc(24rem/16);
        height: calc(2rem/16);
        background-color:var(--color-text-default);
        border-radius: calc(3rem/16);
        transition: transform .2s ease-in-out;
    }
}

.menu-icon__line-3 {
    bottom: 0;
}

.is-open .menu-icon__line-1 {
    opacity: 0;
    transform: translate3d(-17px, 0, 0);
    transition: all .2s ease-in-out;
}

.is-open .menu-icon__line-2 {
    transform: rotate(45deg);

    &:before {
        transform: rotate(-90deg);
        background-color: #fff;
    }
}

.is-open .menu-icon__line-3 {
    opacity: 0;
    transform: translate3d(17px, 0, 0);
    transition: all .2s ease-in-out;
}

.is-open .menu-icon__line {
    background-color:#fff;
}
