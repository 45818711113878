

.language-switch {
    padding: 30px;
    border: 3px solid #fff;
    margin-top: 40px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size:calc(40rem/16);
    font-family:var(--font-headline-italic);
    text-align:center;
    color:#fff;
    cursor:pointer;
    background-color:transparent;
    transition: all 0.2s ease;
}

.language-switch:hover {
    background-color:var(--color-primary);
    color:#fff;
}