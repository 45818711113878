


.weather-row {
    letter-spacing:1px;
    overflow-y: hidden;
    overflow-x: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    @media screen and (max-width: 767px) {
        overflow-x: scroll; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
    }
}
.weather-item {
    background-color: var(--color-dark);
    color: #fff;
    flex: 0 0 15%;
    max-width: 15%;
    border-right:1px solid #fff;
    height: auto;
    @media screen and (max-width: 767px) {
        flex: 0 0 50vw;
        max-width: 50vw;
    }
}

.weather-item__content {
    padding: calc(50rem/16) calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding:calc(25rem/16) calc(10rem/16);
    }
}

.weather-item--has-details {
    flex: 0 0 40%;
    max-width: 40%;
    transition: all 260ms ease;
    cursor:pointer;
    @media screen and (max-width: 767px) {
        flex: 0 0 165vw;
        max-width: 165vw;
    }
}

.weather-item--has-details.detail-shown {
    flex: 0 0 70%;
    max-width: 70%;
    @media screen and (max-width: 767px) {
        flex: 0 0 165vw;
        max-width: 165vw;
    }
}
.weather-item__day {
    font-size:calc(18rem/16);
    font-family:var(--font-headline-italic);
    letter-spacing: 0.2px;
    line-height:calc(22rem/16);
    text-align:center;
}

.weather-item--has-details .weather-item__day {
    @media screen and (min-width: 767px) {
        font-size: calc(46rem / 16);
        font-family: var(--font-headline-italic);
        letter-spacing: 0.6px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
}

.weather-item__date {
    font-size: calc(22rem/16);
    font-family:var(--font-default-bold);
    text-align:center;
    display:block;
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }
}

.weather-item__main-img {
    padding-bottom:calc(30rem/16);
    padding-top:calc(50rem/16);
    width:calc(88rem/16);
    height:calc(195rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        padding-bottom: calc(10rem/16);
        padding-top:calc(10rem/16);
        height:auto;
        width:calc(50rem/16);
    }
}

.weather-item--has-details .weather-item__main-img {
    @media screen and (min-width: 767px) {
        width:calc(170rem/16);
    }
}

.weather-item__rain-percentage {
    font-size:calc(14rem/16);
    font-family:var(--font-default);
    letter-spacing: 1px;
    line-height:calc(19rem/16);
}

.weather-item--has-details .weather-item__rain-percentage {
    @media screen and (min-width: 767px) {
        font-size: calc(25rem / 16);
        line-height: calc(32rem / 16);
        font-family: var(--font-headline-italic);
    }

}

.weather-item__degrees {
    font-size:calc(16rem/16);
    line-height:calc(22rem/16);
    font-family:var(--font-headline-italic);
    @media screen and (max-width: 1090px) {
        font-size:calc(18rem/16);
    }
}

.weather-item--has-details .weather-item__degrees {
    @media screen and (min-width: 1091px) {
        font-size: calc(55rem / 16);
        line-height: 1;
    }
}

.weather-item__degrees-unit {
    font-size:calc(16rem/16);
}
.weather-item--has-details .weather-item__degrees-unit {
    font-size:calc(28rem/16);
    @media screen and (max-width:767px) {
        font-size:calc(18rem/16);
    }
}

.weather-item__open-details {
    cursor:pointer;
    background-color: #434343;
    width:calc(50rem/16);
    height:100%;
    position:relative;
    display:inline-block;
    transition:background-color 0.2s ease;
}
.weather-item--has-details:hover .weather-item__open-details {
    background-color: #595959;
}

.weather-item--has-details:not(.detail-shown):hover .weather-item__open-details .icon {
    animation: moveDetailArrow 2s infinite;
}
.weather-item__open-details-rotated {
    position: absolute;
    width:calc(200rem/16);
    text-align:center;
    left: 50%;
    letter-spacing:1px;
    top:50%;
    transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}
.weather-item__details__description {
    opacity:0;
    height:0;
    visibility:hidden;
    max-height: 300px;
    transition: all 0.5s 0.2s ease;
    @media screen and (max-width: 767px) {
        opacity:1;
        height:auto;
        visibility:visible;
        max-height:none;
        font-size:10px;
    }
}
.detail-shown .weather-item__details__description {
    opacity:1;
    height:auto;
    max-height: 300px;
    visibility:visible;
    transition: all 1.5s 0.2s ease;
}
.detail-shown .weather-item__open-details-rotated .icon {
    transform: rotate(180deg);
    transition: all 0.2s 0.2s ease;
}
.weather-item__details {
    height:100%;
    border-left: 1px solid #434343;
    transition: visibility 0.001ms 180ms, width 260ms ease, opacity 210ms;
    @media screen and (max-width: 767px) {
        width:auto;
    }
    @media screen and (min-width: 768px) {
        width:0;
        visibility:hidden;
        opacity:0;
    }
}

.weather-item__details.active {
    @media screen and (min-width: 768px) {
        transition: visibility 0.0001ms, width 260ms ease, height 250ms ease, opacity 210ms;
        visibility: visible;
        opacity:1;
    }
    @media screen and (max-width: 1199px) {
        width: 14.75rem;
    }

    @media screen and (min-width: 1200px) {
        width: calc(332rem/16);
    }

}
.weather-item__details-row {
    height: 25%;
    border-bottom: 1px solid #434343;
    @media screen and (max-width: 767px) {
        height: 20%;
        padding-bottom: 10px;
        padding-top: 10px;
    }
}
.weather-item__details-row:first-of-type {
    height: 50%;
    @media screen and (max-width: 767px) {
        height: 60%;
        padding-bottom: 10px;
        padding-top: 10px;
    }
}
.weather-item__details__img {
    width:calc(54rem/16);
    padding-top:calc(22rem/16);
    padding-bottom:calc(20rem/16);
    margin:0 auto;
    @media screen and (max-width: 1199px) {
        width:calc(45rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-top:calc(10rem/16);
        padding-bottom:calc(10rem/16);
        width:calc(37rem/16);
    }
}

.weather-item__details__degrees {
    font-size:calc(31rem/16);
    line-height:1;
    font-family:var(--font-headline-italic);
    @media screen and (max-width: 1199px) {
        font-size:calc(22rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(26rem/16);

    }
}

.weather-item__details__degrees-unit {
    font-size:calc(21rem/16);
}

.weather-item__details__rain-percentage {
    font-size:calc(14rem/16);
    font-family:var(--font-default);
}



@keyframes moveDetailArrow {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(0, 2px);
    }
    60% {
        transform: translate(0, -2px);
    }
    100% {
        transform: translate(0, 0);
    }
}