.comparison-teaser {
    padding-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(40rem/16);
    }
}
.comparison-teaser__title {
    font-family: var(--font-default-bold);
    font-size: calc(23rem/16);
    line-height: calc(28/23);
    text-transform: uppercase;
    margin-top: calc(21rem/16);
}
.comparison-teaser__validity {
    padding-top: calc(20rem/16);
    padding-bottom: calc(28rem/16);
}
.comparison-teaser__validity-title {
    text-transform: uppercase;
    font-size: calc(18rem/16);
    line-height: calc(28/18);
    font-family: var(--font-default-bold);
    min-height: calc(28rem/16);
}
.comparison-teaser__validity-text {
    font-size: calc(15rem/16);
}
.comparison-teaser__accordion-wysiwyg ul li {
    margin-bottom: calc(10rem/16);
}
.comparison-teaser-slider__item {
    padding-left: calc(6rem/16);
    padding-right: calc(6rem/16);
}
.comparison-teaser-slider {
    padding-right: calc(30rem/16);
}
.comparison-teaser-slider__arrow.arrow-prev {
    margin-bottom:10px;
}
.comparison-teaser-slider__arrows {
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    right:0;
    color: var(--color-primary);
}
.comparison-teaser-slider__arrows .teaser:before {
    @media screen and (min-width: 768px) {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        top: 0;
        left: 0;
        transition: opacity 0.3s ease;
        z-index: 2;
    }
}
.comparison-teaser-slider__arrows .slick-active .teaser:before,
.comparison-teaser-slider__arrows .slick-active .teaser:before {
    opacity:0;
}