body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    font-size: calc(78rem/16);
    font-family:var(--font-headline);
    letter-spacing: 1.38px;
    line-height:calc(72/78);
    text-transform:uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(32rem / 16);
        letter-spacing: 0.88px;
    }
    @media screen and (max-width: 1400px) {
        font-size:calc(54rem/16);
    }

}
h2, .h2 {
    font-size: calc(46rem/16);
    font-family:var(--font-headline-italic);
    letter-spacing: 0.92px;
    text-transform:none;
    line-height:calc(52/46);
    @media screen and (max-width: 767px) {
        font-size:calc(28rem/16);
        letter-spacing: 0.92px;
        line-height: calc(34/28);
    }
}
h3, .h3 {
    font-size: calc(26rem/16);
    font-family:var(--font-headline-italic);
    letter-spacing: 0.6px;
    line-height:calc(35/26);
    @media screen and (max-width: 1400px) {
        font-size:calc(22rem/16);
        line-height:calc(28/26);
    }
}
h4, .h4 {
    font-size: calc(18rem/16);
    font-family:var(--font-headline-italic);
    letter-spacing: 0.24px;
    line-height:calc(22/18);
}
h5, .h5 {
    font-size: calc(16rem/16);
}
h6, .h6 {
    font-size: 1em;
}

