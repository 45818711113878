

.img-teaser {
    position:relative;
    backface-visibility: hidden;
}
.img-teaser:not(.img-teaser-halfed) .embed-responsive-item, .img-teaser-halfed .img-teaser-halfed__img {
    transition: transform .5s ease;
    -webkit-backface-visibility: hidden;

}
.img-teaser:not(.img-teaser-halfed):hover .embed-responsive-item, .img-teaser-halfed:hover .img-teaser-halfed__img {
    transform: scale3d(1.02,1.02,1);
}

.img-teaser .teaser__description {
    font-size: calc(12rem/16);
    @media screen and (max-width: 1199px) {
        padding-right: calc(10rem/16);
    }
}

.img-teaser__body {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: 1;
    color:#fff;
    padding: calc(57rem/16) calc(24rem/16) calc(36rem/16) calc(24rem/16);
    @media screen and (max-width: 1400px) {
        padding: calc(45rem/16) calc(24rem/16) calc(24rem/16) calc(24rem/16);
    }
    @media screen and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(24rem/16) calc(24rem/16) calc(24rem/16) calc(24rem/16);
    }
}

.img-teaser__body--top, .img-teaser__body--bottom {
    z-index: 1;
}
.img-teaser__body:before, .simple-img-teaser__body:before {
    opacity:1;
    content:'';
    width:100%;
    top:0;
    left:0;
    height:100%;
    position:absolute;
    display:block;
    transition:all  0.5s ease-out;
    background: linear-gradient(135deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);

}
.img-teaser__body:after, .simple-img-teaser__body:after {
    opacity:0;
    content:'';
    width:100%;
    top:0;
    left:0;
    height:100%;
    position:absolute;
    display:block;
    transition:all  0.5s ease-out;
    background: rgba(0,0,0,.2);
    z-index: -1;
    @media screen and (max-width: 767px) {
        opacity: 1;
    }
}

.img-teaser__body:hover:after, .simple-img-teaser__body:hover:after {
    opacity: 1;
}

.img-teaser__advertising-img {
    position: absolute;
    width: calc(65rem/16);
    bottom: .625rem;
    top: 0;
    right: 0;
    @media screen and (min-width: 768px){
        width: calc(100rem/16);
    }
    @media screen and (min-width: 1200px){
        width: calc(180rem/16);
    }

}
.img-teaser .teaser__body:hover .img-teaser__advertising-img {
    z-index: -1;
}
.img-teaser__advertising-img--wide {
    @media screen and (max-width: 767px) {
        position:absolute;
        width: calc(120rem/16);
        bottom:calc(10rem/16);
        right:calc(10rem/16);
        top:initial;
    }
}


.img-teaser__advertising-img--wide img {
   max-width:calc(200rem/16);
    @media screen and (max-width: 767px) {
        max-width:calc(150rem/16);
    }
}
.img-teaser-halfed {
    overflow:hidden;
}
.img-teaser-halfed .img-teaser__body {
    background-color:#efefef;
    position:relative;
    padding: calc(20rem/16) 1.5rem 2.25rem;
    color:#000;
    text-shadow:none;
    transition: all 0.2s ease;
    flex: 1 auto;
    height:auto;
    min-height: calc(200rem/16);
    @media screen and (max-width: 1199px) {
        padding: calc(10rem/16);
        min-height: calc(150rem/16);
    }
}

.img-teaser-halfed:hover .img-teaser__body {
    background-color:#cdcdcd;
}

.img-teaser-halfed .img-teaser__body:before {
    content:'';
    position:absolute;
    display:block;
    top:-22px;
    left:0;
    right:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 0 0 341px;
    transition: all 0.2s ease;
    border-color: transparent transparent transparent #efefef;
}
.img-teaser-halfed:hover .img-teaser__body:before {
    border-color: transparent transparent transparent #cdcdcd;

}
.img-teaser-halfed .img-teaser__body:after{
    display: none;
}

.img-teaser-halfed .teaser__title {
    text-shadow:none;
}

.img-teaser-halfed .teaser__link {
    color:var(--color-primary);
}

.img-teaser-halfed .teaser__link:before {
    background-color:var(--color-primary-light);
}

.img-teaser-halfed .teaser__location {
    text-shadow:none;
}

/* ---- simple-img-teaser */

.simple-img-teaser__body {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    padding: calc(24rem/16);
    z-index: 3;
    overflow:hidden;
    color:#fff;
    @media screen and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
}
.simple-img-teaser__title {
    font-size:calc(14rem/16);
    font-family:var(--font-default-bold);
    text-shadow: 0 1px 2px rgba(0,0,0,0.2);
    color:#fff;
    z-index: 1;
}


.simple-img-teaser.teaser--has-hover-overlay:after {
    @media screen and (max-width: 767px) {
        opacity:1;
    }
}
.simple-img-teaser .teaser__link-text {
    @media screen and (max-width: 767px) {
        opacity:1;
    }
}

.simple-img-teaser .teaser__hover-text {
    @media screen and (max-width: 767px) {
        opacity:1;
        transform:translateY(0px);
        z-index: 3;
    }
}



/* new img-teaser styling */
.img-teaser--new .img-teaser__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calc(16rem/16) calc(16rem/16) calc(20rem/16);

    &:before {
        z-index: -1;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
    }

    @media (min-width: 1200px) {
        padding: clamp(0.5rem, 2.222vw - 1.167rem, 1.5rem) clamp(0.5rem, 3.889vw - 2.417rem, 2.25rem) clamp(0.5rem, 3.333vw - 2rem, 2rem);
    }
}

.img-teaser--new.img-teaser--light-overlay .img-teaser__body {
    &:before {
        background: linear-gradient(180deg,hsla(0,0%,100%,0) 42%,hsla(0,0%,100%,.75) 64%,hsla(0,0%,100%,.85));
    }

    &:after {
        background: rgba(250, 250, 250, 0.2);
    }
}

.img-teaser--new .img-teaser__body.img-teaser__body--has-highlight {
    padding-top: calc(44rem/16);
}

.img-teaser--new .img-teaser__body-top {
    display: flex;
    justify-content: space-between;
    gap: calc(4rem/16);
    
    @media (min-width: 768px) {
        gap: calc(8rem/16);        
    }
}

.img-teaser--new .img-teaser__badge {
    background-color: var(--color-primary);
    color: #fff;
    padding: calc(8rem/16) calc(10rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    display: flex;
    align-items: center;
    gap: calc(4rem/16);
    height: fit-content;
}

.img-teaser--new .img-teaser__badge.img-teaser__badge--light {
    background-color: rgba(250, 250, 250, 0.5);
    backdrop-filter: blur(calc(8rem/16));
    color: var(--color-dark);
}

.img-teaser--new .img-teaser__badge.img-teaser__badge--dark {
    background-color: rgba(50, 50, 50, 0.2);
    backdrop-filter: blur(calc(8rem/16));
}

.img-teaser--new .teaser__title {
    font-family: var(--font-headline-italic);
    font-size: calc(26rem/16);
    line-height: calc(32/26);
    color: #fff;
    text-shadow: none;
}

.img-teaser--new .teaser__category {
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    color: #fff;
    text-shadow: none;
    margin-bottom: 0;
}

.img-teaser--new .teaser__description {
    font-family: var(--font-default);
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    color: #fff;
    padding-top: calc(4rem/16);
}

.img-teaser--new.img-teaser--light-overlay .teaser__title,
.img-teaser--new.img-teaser--light-overlay .teaser__category,
.img-teaser--new.img-teaser--light-overlay .teaser__description {
    color: var(--color-dark);
}