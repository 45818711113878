

.btn {
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
    text-transform: uppercase;
    letter-spacing:1px;
    @media screen and (max-width: 767px) {
        padding-left: calc(20rem/16);
        padding-right:calc(20rem/16);
    }
}

.btn-primary {
    background: none;
    position: relative;
    overflow: hidden;
    border: none;
    z-index: 1;

    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: var(--primary-gradient);
        z-index: -1;
    }

    &:after {
        background: linear-gradient(90deg, transparent 0%, var(--color-primary-dark) 10%, var(--color-primary) 100%);
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
    }

    &:hover:after {
        transform: scaleX(1.2);
    }

    &:hover {
        background: none;
    }
}

.btn-scroll-down {
    background-color: rgba(0,0,0,.7);
    color:#fff;
    font-size:calc(12rem/16);
    padding:calc(15rem/16) calc(15rem/16);
    right:0;
    z-index: 2;
    width:100%;
    position:absolute;
    @media screen and (max-width: 767px) {
        bottom:calc(55rem/16);
        top:initial !important;
        width: calc(122rem/16);
        position: fixed;
    }
}

.btn-scroll-down:hover {
    color:#fff;
}

.btn-scroll-down__badge {
    position: absolute;
    top: calc(-38rem/16);
    background-image:url("/static/img/backgrounds/bg-tabbing-border.svg");
    background-size:cover;
    width: 35px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    left:-1px;
    color:#000;
}
.btn-scroll-down-wrapper {
    position: absolute;
    right: 0;
    width: calc(122rem/16);
    margin-left: auto;
}
.btn-scroll-down .icon {
    font-size:calc(7rem/16);
    margin-top:calc(10rem/16);
}

.btn-inverted {
    background-color:#fff;
    color:var(--color-primary);
}

.btn-inverted:hover {
    color:#fff;
    border:1px solid #fff;
    background-color:var(--color-primary);
}
.btn--no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.btn-block {
    padding-left:calc(5rem/16);
    padding-right:calc(5rem/16);
    white-space:initial;
}

.btn--no-styling:focus, .btn--no-styling:active {
    outline:none;
}

.btn-nauders {
    background-color:var(--color-nauders);
    color:#fff;
    border: 2px solid var(--color-nauders);
}
.btn-nauders:hover {
    background-color:#fff;
    color:var(--color-nauders);
}
.btn-kaunertal {
    background-color:var(--color-kaunertal);
    color:#fff;
    border: 2px solid var(--color-kaunertal);

}
.btn-kaunertal:hover {
    background-color:#fff;
    color:var(--color-kaunertal);
}
.btn-tiroler-oberland {
    background-color:var(--color-tiroler-oberland);
    color:#fff;
    border: 2px solid var(--color-tiroler-oberland);
}

.btn-tiroler-oberland:hover {
    background-color:#fff;
    color:var(--color-tiroler-oberland);
}

.btn-grey {
    background-color:var(--color-grey);
    color:#fff;
    border: 2px solid var(--color-grey);
}

.btn-grey:hover {
    background-color:#fff;
    color:var(--color-grey);
}

.btn-booking {
    background-color:var(--color-booking);
    color:#fff;
}

.btn-booking:hover {
    background-color:var(--color-booking-hover);
}

.btn-radio {
    background-color:var(--color-middlegrey);
    color:var(--color-primary);
    text-transform: uppercase;
    border-radius: calc(30rem/16);
    padding:calc(9rem/16) calc(35rem/16);
    font-family:var(--font-default);
}
.btn-radio--lg {
    padding:calc(12rem/16) calc(35rem/16);
    font-size: .9375rem;
}
.btn-radio:hover,
.btn-radio:focus-visible{
    background-color:var(--color-primary-light);
}
.btn-radio.active {
    background-color:var(--color-primary);
    color:#fff;
}
.btn-radio input {
    appearance: none;
}

a.btn-primary.disabled, a.btn-primary:disabled {
    color:#fff;
}


.btn-radio--all {
    color:#000;
}
.btn-radio--all:hover,
.btn-radio--all:focus,
.btn-radio--all.active {
    background-color:var(--color-darkgrey);
    color:#fff;
}
.btn-radio--tol {
    color:var(--color-tiroler-oberland);
}
.btn-radio--tol:hover,
.btn-radio--tol:focus,
.btn-radio--tol.active {
    background-color:var(--color-tiroler-oberland);
    color:#fff;
}
.btn-radio--nauders {
    color:var(--color-nauders);
}
.btn-radio--nauders:hover,
.btn-radio--nauders:focus,
.btn-radio--nauders.active {
    background-color:var(--color-nauders);
    color:#fff;
}
.btn-radio--kaunertal{
    color:var(--color-kaunertal);
}
.btn-radio--kaunertal:hover,
.btn-radio--kaunertal:focus,
.btn-radio--kaunertal.active {
    background-color:var(--color-kaunertal);
    color:#fff;
}