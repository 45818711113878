.single-teaser {
    background-color: #fff;
    box-shadow: 0 0 16px 8px rgba(0,0,0,0.1);
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas: 'content'
                         'image';

    @media (min-width: 768px) {
        grid-template-rows: 1fr;
        grid-template-columns: 5fr 7fr;
        grid-template-areas: 'content image';
    }
}

.single-teaser.single-teaser--img-first {
    grid-template-areas: 'image'
                         'content';

    @media (min-width: 768px) {
        grid-template-columns: 7fr 5fr;
        grid-template-areas: 'image content';
    }
}

.single-teaser.single-teaser--vertical {
    @media (min-width: 768px) {
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: 'content'
                            'image';
    }

    &.single-teaser--img-first {
        grid-template-areas: 'image'
                            'content';
    }
}

.single-teaser__content {
    grid-area: content;
    padding: calc(16rem/16) calc(20rem/16);

    @media (min-width: 768px) {
        padding: clamp(1rem, 2.778vw - 0.333rem, 3rem) clamp(1.25rem, 3.125vw - 0.25rem, 3.5rem);
    }
}

.single-teaser__headline {
    position: relative;
    padding-bottom: calc(4rem/16);
    margin-bottom: calc(16rem/16);

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(120rem/16);
        height: calc(4rem/16);
        background: var(--primary-gradient);
    }

    @media (min-width: 768px) {
        margin-bottom: calc(20rem/16);
    }
}

.single-teaser__title {
    font-family: var(--font-headline-italic);
    font-size:calc(28rem/16);
    line-height: calc(34/34);

    @media (min-width: 768px) {
        font-size: calc(40rem/16);
        line-height: calc(52/40);
    }
}

.single-teaser__subtitle {
    font-size: calc(14rem/16);
    line-height: calc(18/14);
    text-transform: uppercase;
    
    @media (min-width: 768px) {
        font-size: calc(20rem/16);
        line-height: calc(25/20);
    }
}

.single-teaser__link {
    margin-top: calc(24rem/16);
    width: 100%;
    z-index: 0;

    @media (min-width: 768px) {
        margin-top: calc(30rem/16);
        width: fit-content;
    }
}

.single-teaser__img-wrapper {
    grid-area: image;
    position: relative;
}

.single-teaser__img-wrapper img {
    object-fit: cover;
}

.single-teaser__advertising-img {
    position: absolute;
    top: 0;
    right: 0;
}