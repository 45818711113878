

.fullscreen-button, .leaflet-control-fullscreen {
    background-color: var(--color-primary);
    position: absolute;
    bottom: 0;
    width: calc(79rem/16);
    height: calc(79rem/16);
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    font-size:calc(40rem/16);
    transition: all 0.2s ease;
    z-index: 2;
    cursor: pointer;
    border: none;
    @media screen and (max-width: 767px) {
        width:calc(50rem/16);
        height:calc(50rem/16);
        font-size:calc(30rem/16);
    }
}


.is-fullscreen .fullscreen-button {
    width:auto;
    font-size:calc(20rem/16);
}

.is-fullscreen .fullscreen-button .icon {
    margin-right:10px;
    font-size:calc(14rem/16);
}
.is-fullscreen .fullscreen-button:before {
    display:none;
}
.fullscreen-button:hover {
    color:#fff;
    transform:translateX(10px) translateY(-10px);
    transition: all 0.2s ease;
}

.fullscreen-button:before, .leaflet-control-fullscreen:before {
    position: absolute;
    content:'';
    top: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    left:0;
    border-width: 7px 0 0 79px;
    border-color: transparent transparent transparent var(--color-primary);
    @media screen and (max-width: 767px) {
        border-width: 7px 0 0 50px;
    }
}

.fullscreen-button:after, .leaflet-control-fullscreen:after {
    position: absolute;
    content:'';
    right: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    bottom:0;
    border-width: 79px 0 0 9px;
    border-color: transparent transparent transparent  var(--color-primary);
    @media screen and (max-width: 767px) {
        border-width: 50px 0 0 9px;

    }
}
