.panorama-teaser {
    position:relative;

    &.panorama-teaser--overlay-primary:before,
    &:after {
        opacity: 1;
        content: "";
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        display: block;
        transition: all .5s ease-out;
        background: linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
    }

    &.panorama-teaser--overlay-primary:before {
        background: linear-gradient(270deg, var(--color-primary-dark) 0%, var(--color-primary) 100%);
        opacity: 0.3;
        z-index: 1;
    }
}

.panorama-teaser__body {
    position:absolute;
    top:0;
    left:0;
    color:#fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height:100%;
    max-width:100%;
    padding:calc(20rem/16);
    z-index: 2;

    @media (min-width: 768px) {
        padding: 0;
        margin-left:calc(182rem/16);
        max-width:calc(500rem/16);
        justify-content: center;
    }
}

.panorama-teaser .teaser__title {
    font-family: var(--font-headline-italic);
    font-size:calc(28rem/16);
    line-height: calc(34/34);

    @media (min-width: 768px) {
        font-size: calc(40rem/16);
        line-height: calc(52/40);
    }
}

.panorama-teaser .teaser__button {
    margin-top: calc(24rem/16);
    
    @media (min-width: 768px) {
        margin-top: calc(36rem/16);        
    }
}