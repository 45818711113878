.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}

.row--gutter-width-0 > .col,
.row--gutter-width-0 > [class^="col"],
.row--gutter-width-0 > [class*=" col"] {
    padding-left:0;
    padding-right:0;
}
.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}

.row--gutter-sponsor-area > .col,
.row--gutter-sponsor-area > [class^="col"],
.row--gutter-sponsor-area > [class*=" col"] {
    padding-left:15px;
    padding-right:15px;
    @media screen and (max-width: 991px) {
        padding-left:5px;
        padding-right:5px;
    }
}

.row--gutter-width-10 > .col,
.row--gutter-width-10 > [class^="col"],
.row--gutter-width-10 > [class*=" col"] {
    padding-left:5px;
    padding-right:5px;
}
.row.row--gutter-width-12 {
    margin-left:-6px;
    margin-right:-6px;
}

.row--gutter-width-12 > .col,
.row--gutter-width-12 > [class^="col"],
.row--gutter-width-12 > [class*=" col"] {
    padding-left:6px;
    padding-right:6px;
}
.row.row--gutter-width-20 {
    margin-left:-10px;
    margin-right:-10px;
}

.row--lp-teaser-gutter > .col,
.row--lp-teaser-gutter > [class^="col"],
.row--lp-teaser-gutter > [class*=" col"] {
    padding-left:12px;
    padding-right:12px;
    margin-top: 24px;
    @media screen and (max-width: 767px) {
        margin-top: 8px;
        padding-left:4px;
        padding-right:4px;
    }
}
.row.row--lp-teaser-gutter {
    margin-top:-24px;
    margin-left:-12px;
    margin-right:-12px;
    @media screen and (max-width: 767px) {
        margin-top:-8px;
        margin-left:-4px;
        margin-right:-4px;
    }
}

.row--gutter-width-20 > .col,
.row--gutter-width-20 > [class^="col"],
.row--gutter-width-20 > [class*=" col"] {
    padding-left:10px;
    padding-right:10px;
}


.row--gutter-width-60 > .col,
.row--gutter-width-60 > [class^="col"],
.row--gutter-width-60 > [class*=" col"] {
    padding-left:30px;
    padding-right:30px;
}

.row--gutter-width-70 > .col,
.row--gutter-width-70 > [class^="col"],
.row--gutter-width-70 > [class*=" col"] {
    padding-left:35px;
    padding-right:35px;
}

.row.row--gutter-width-94 {
    margin-left:-47px;
    margin-right:-47px;
}

.row--gutter-width-94 > .col,
.row--gutter-width-94 > [class^="col"],
.row--gutter-width-94 > [class*=" col"] {
    padding-left:47px;
    padding-right:47px;
}


.row--gutter-primary-border {
    border-top:1px solid var(--color-primary);
    border-left: 1px solid var(--color-primary);
}

.row--gutter-primary-border > .col:first-of-type,
.row--gutter-primary-border > [class^="col"]:first-of-type,
.row--gutter-primary-border > [class*=" col"]:first-of-type {
    @media screen and (max-width: 767px) {
        border-top:1px solid var(--color-primary);
    }
}
.row--gutter-primary-border > .col,
.row--gutter-primary-border > [class^="col"],
.row--gutter-primary-border > [class*=" col"]{
    border-right: 1px solid  var(--color-primary);
    border-bottom: 1px solid  var(--color-primary);
    @media screen and (max-width: 767px) {
        border-left:1px solid var(--color-primary);
        border-top:inherit;
    }
}

.row--gutter-grey-border {
    border-top:1px solid var(--color-lightestgrey);
    border-left: 1px solid  var(--color-lightestgrey);
}

.row--gutter-grey-border > .col:first-of-type,
.row--gutter-grey-border > [class^="col"]:first-of-type,
.row--gutter-grey-border > [class*=" col"]:first-of-type {
    @media screen and (max-width: 767px) {
        border-top:1px solid  var(--color-lightestgrey);
    }
}
.row--gutter-grey-border > .col,
.row--gutter-grey-border > [class^="col"],
.row--gutter-grey-border > [class*=" col"]{
    border-right: 1px solid  var(--color-lightestgrey);
    border-bottom: 1px solid   var(--color-lightestgrey);
    @media screen and (max-width: 767px) {
        border-left:1px solid var(--color-lightestgrey);
        border-top:inherit;
    }
}


.row--gutter-white-border {
    border-left: 1px solid #fff;
}

.row--gutter-white-border > .col:first-of-type,
.row--gutter-white-border > [class^="col"]:first-of-type,
.row--gutter-white-border > [class*=" col"]:first-of-type {
    @media screen and (max-width: 767px) {
        border-top:1px solid #fff;
    }
}
.row--gutter-white-border > .col,
.row--gutter-white-border > [class^="col"],
.row--gutter-white-border > [class*=" col"],
.row--gutter-white-border > .slick-list .col,
.row--gutter-white-border > .slick-list [class^="col"],
.row--gutter-white-border > .slick-list [class*=" col"]
{
    border-top:1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    @media screen and (max-width: 767px) {
        border-left:1px solid #fff;
        border-top:inherit;
    }
}

.row--gutter-white-border + .row--gutter-white-border > .col,
.row--gutter-white-border + .row--gutter-white-border > [class^="col"],
.row--gutter-white-border + .row--gutter-white-border > [class*=" col"],
.row--gutter-white-border + .row--gutter-white-border > .slick-list .col,
.row--gutter-white-border + .row--gutter-white-border > .slick-list [class^="col"],
.row--gutter-white-border + .row--gutter-white-border > .slick-list [class*=" col"]{
    border-top: 0px;
}