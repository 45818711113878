

/* ------ img-text-slider ------ */
.img-text-slide__item__text {
    @media screen and (min-width: 768px) {
        padding-right:calc(20rem/16);
        opacity:0;
        transform: translateX(-100px);
        transition: all 0.2s ease-out;
    }
}
.img-text-slide__item__date {
    font-size:calc(14rem/16);
    font-family:var(--font-default-bold);
    letter-spacing:1.1px;
}
.img-text-slide__item__headline {
    font-size:calc(35rem/16);
    font-family:var(--font-default-bold);
    padding-top:calc(15rem/16);
    line-height:calc(41/35);
    @media screen and (max-width: 767px) {
        font-size:calc(22rem/16);
    }
}
.img-text-slide__item__description {
    font-size:calc(15rem/16);
    margin-bottom:calc(40rem/16);
    margin-top: calc(15rem/16);
}

.img-text-slide__item, .img-text-slider .slick-slide {
    @media screen and (min-width: 1241px) {
        width:1150px;
    }
    @media screen and (max-width: 1240px) and (min-width: 768px) {
        width:850px;
    }
    @media screen and (max-width: 767px) {
        width:100%;
    }
}


.slick-center .img-text-slide__item__text,
.slick-current .img-text-slide__item__text {
    @media screen and (min-width: 768px) {
        opacity: 1;
        transform: translateX(0px);
    }
}

.img-text-slider .slick-slide .slide__item__img {
    @media screen and (min-width: 768px) {
        transition: transform 0.5s ease;
        position: relative;
    }
}
.img-text-slider .slide__item__img {
    @media screen and (min-width: 768px) {
        transform: translateX(100%);
    }
}

.img-text-slider .slick-current + .slick-slide .slide__item {
    transform: translateX(50px);
    @media screen and (max-width: 767px) {
        transform:translateX(10px);
    }
}
.img-text-slider .slick-center .slide__item__img,
.img-text-slider .slick-center ~ .slick-slide .slide__item__img,
.img-text-slider .slick-current .slide__item__img,
.img-text-slider .slick-current ~ .slick-slide .slide__item__img {
    @media screen and (min-width: 768px) {
        transform: translateX(0);
    }
}
.img-text-slider .slide__item__img:after {
    @media screen and (min-width: 768px) {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        top: 0;
        left: 0;
        transition: opacity 0.3s ease;
    }
}
.img-text-slide__item:after {
    @media screen and (max-width: 767px) {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        top: 0;
        left: 0;
        transition: opacity 0.3s ease;
        opacity:1;
        pointer-events: none;
    }
}
.img-text-slider .slick-active .img-text-slide__item:after,
.img-text-slider .slick-current .img-text-slide__item:after {
    @media screen and (max-width: 767px) {
        opacity:0;
    }
}
.img-text-slider .slick-center .slide__item__img:after,
.img-text-slider .slick-current .slide__item__img:after {
    opacity:0;
}

.img-text-slider__arrow {
    color:var(--color-primary);
    border:1px solid var(--color-primary);
    font-size:calc(18rem/16);
    position: absolute;
    bottom: 22px;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    display:inline-flex;
    right: calc(50vw - 646px);
    transition:all 0.2s ease;
    width:calc(75rem/16);
    height:calc(45rem/16);
    @media screen and (max-width: 1400px) {
        right:10px;
    }
}

.img-text-slider__arrow:hover {
    background-color:var(--color-primary);
    color:#fff;
}
.img-text-slider__arrow:hover .icon {
    animation: moveArrow 2s forwards infinite;

}

.img-text-slider--new .slick-track {
    display: flex;
}

.img-text-slider--new .slick-slide {
    float: none;
    height: auto;
}

.img-text-slider--new .slick-slide > div,
.img-text-slider--new .slide__item {
    height: 100%;
}

.img-text-slide__item-content {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    
    @media (min-width: 768px) {
        grid-template-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
        gap: calc(30rem/16);
    }
}

.img-text-slide__item-content .img-text-slide__item__text {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) {
        justify-content: flex-start;
    }
}

@keyframes moveArrow {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(5px, 0);
    }
    60% {
        transform: translate(-5px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}
.img-text-slider__arrow.arrow-prev {
    bottom: 80px;
}

.img-text-slider .slide__item {
    @media screen and (max-width: 767px) {
        padding-left:calc(8rem/16);
        padding-right:calc(8rem/16);
        margin-left:calc(8rem/16);
    }
}

/* new Top-Event-Teaser styling */
.img-text-slide__item-subtitle {
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    line-height: calc(24/16);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;
    margin-top: calc(8rem/16);

    @media (min-width: 768px) {
        font-size: calc(20rem/16);
        line-height: calc(30/20);
    }
}

.slide__item-img-badge {
    background-color: var(--color-primary);
    color: #fff;
    padding: calc(8rem/16) calc(10rem/16);
    font-family: var(--font-default-bold);
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    position: absolute;
    left: calc(28rem/16);
    top: calc(12rem/16);

    @media (min-width: 768px) {
        left: calc(16rem/16);
    }
}

.img-text-slider .slide__item__img:after {
    @media (min-width: 768px) {
        height: 95.67%;
    }
}

.img-text-slide__item__description .wysiwyg {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    overflow: hidden;
}

.img-text-slider--new + .img-text-slider__arrows {
    @media (max-width: 767px) {
        display: flex;
        justify-content: center;
        gap: calc(12rem/16);
        right: 0;
        margin-top: calc(12rem/16);
    }
}

.img-text-slider--new + .img-text-slider__arrows .img-text-slider__arrow {
    padding: calc(16rem/16) calc(42rem/16);
    width: fit-content;
    border-width: calc(2rem/16);
    position: relative;
    transform: none;
    bottom: 0;
    left: 0;

    &:hover .icon {
        animation: none;
    }
}