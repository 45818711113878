
.form-group--styled {
    background-color:#fff;
    padding:calc(18rem/16);
}

.form-group__label {
    text-transform: uppercase;
    font-size:calc(14rem/16);
    letter-spacing: 1.1px;
    line-height: calc(19/14);
    color:var(--color-primary);
}

.form-control[readonly] {
    background-color:transparent;
}