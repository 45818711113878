

.catalogue-teaser {
    height:100%;
    align-items: stretch;
    display:flex;
    flex-direction: column;
    padding-bottom:calc(20rem/16);
    position:relative;

}
.catalogue-teaser__body {
    padding:calc(25rem/16);
    text-shadow: none;
    display:flex;
    flex-grow:1;
    flex-direction:column
}

.catalogue-teaser__title, .catalogue-teaser__description {
    text-shadow:none;
}

.js-catalogue-order-form__form {
    position:relative;
}
.catalogue-teaser .js-catalogue-order-form__form:after {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:transparent;
    transition:all 0.2s ease;
    z-index: -1;
}
.catalogue-teaser--disabled .js-catalogue-order-form__form:after {
    background-color:rgba(255,255,255,0.6);
    z-index: 2;
}