
.info-message-box {
    padding-top:calc(50rem/16);
    padding-bottom:calc(50rem/16);
    z-index: 8;
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 767px){
        width: 80vw;
    }
}

.info-message-box__info {
    font-size:calc(100rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(50rem/16);
        margin:0 auto;
        padding-bottom:calc(20rem/16);
    }
}

.info-message-box__close {
    position:absolute;
    right:calc(20rem/16);
    top: calc(20rem/16);
    font-size:calc(30rem/16);
    cursor:pointer;
    @media screen and (max-width: 767px) {
        right:calc(10rem/16);
    }
}