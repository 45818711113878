

.embed-responsive-19by23 {
    padding-bottom: calc(23 / 19 * 100%);
}
.embed-responsive-2by1 {
    padding-bottom: calc(1 / 2 * 100%);
}

.embed-responsive-5by4 {
    padding-bottom: calc(4 / 5 * 100%);
}
.embed-responsive-16by7 {
    padding-bottom: calc(7 / 16 * 100%);
}

.embed-responsive-25by36 {
    padding-bottom: calc(36 / 25 * 100%);
}

.embed-responsive-32by9 {
    padding-bottom: calc(9 / 32 * 100%);
}

.embed-responsive-38by33 {
    padding-bottom: calc(33 / 38 * 100%);
}

.embed-responsive-img-gallery {
    padding-bottom: calc(544 / 660 * 100%);
}

.embed-responsive-img-teaser-big {
    padding-bottom: calc( 386/ 308 * 100%);
}
.embed-responsive-img-teaser-wide {
    padding-bottom: 43.29521%;
}
.embed-responsive-img-teaser-small {
    padding-bottom: calc( 331/381  * 100%);
}

.embed-responsive-img-teaser-halfed {
    padding-bottom: calc( 206/341  * 100%);
}

.embed-responsive-webcam {
    padding-bottom: 60%;
    @media screen and (max-width:767px) {
        padding-bottom: 130%;
    }
}

.embed-responsive-img-grid-pic1 {
    padding-bottom: calc( 467/ 386 * 100%);
    @media screen and (max-width:767px) {
        padding-bottom: calc( 271/308  * 100%);
    }
}

.embed-responsive-img-grid-pic2 {
    padding-bottom: calc( 445/790  * 100%);
    @media screen and (max-width:767px) {
        padding-bottom: calc( 271/308  * 100%);
    }
}

.embed-responsive-img-grid-pic3 {
    padding-bottom: calc( 271/308  * 100%);
    @media screen and (max-width:767px) {
        padding-bottom: calc( 271/308  * 100%);
    }
}

.embed-responsive-img-text-slider {
    padding-bottom: calc( 490/563  * 100%);
}

.embed-responsive-simple-img-teaser {
    padding-bottom: calc( 197/226  * 100%);
}

.embed-responsive-simple-img-teaser {
    padding-bottom: calc( 197/226  * 100%);
}

.embed-responsive-comparison-teaser {
    padding-bottom: calc( 229/324  * 100%);
}

.embed-responsive-15by13 {
    padding-bottom:calc( 13/15  * 100%);
}
.embed-responsive-interactive-map {
    padding-bottom:calc( 1371/1920  * 100%);
    @media screen and (max-width: 767px) {
        padding-bottom: 150%;
    }

}
.embed-responsive-special-slide {
    padding-bottom: calc( 280/400  * 100%);
}
.embed-responsive-8by5 {
    padding-bottom: calc(5/8 * 100%);
}

/* only for new image teaser */
.img-teaser--new .embed-responsive-img-teaser-small {
    padding-bottom: calc( 268/303  * 100%);
}