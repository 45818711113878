.wysiwyg table {
    text-align: left;
    width:100%;
    border:none;
    border-color:transparent;
}
.table thead th,
.wysiwyg table thead th, .wysiwyg table th, .table th {
    font-size:calc(14rem/16);
    line-height:calc(28rem/16);
    font-family:var(--font-default-bold);
    text-align:center;
    text-transform: uppercase;
    letter-spacing:1px;
    border-left:none;
    border-right:none;
}

.table td,
.wysiwyg table td {
    font-size:calc(18rem/16);
    text-align:left;

}


.table td, .wysiwyg table td, .table th, .wysiwyg table th {
    border-top:1px solid var(--color-primary-light);
    padding:calc(12rem/16);

}


/* ---- table facility ------ */
.table-fixed {
    table-layout:fixed;
}
.table-facility {
    padding-top:calc(15rem/16);
}
.table-facility + .table-facility {
    padding-top:calc(0rem/16);
}
.table-facility thead th {
    font-family:var(--font-headline-italic);
    border-top:none;
    font-size:calc(18rem/16);
    text-transform: none;
    border-bottom:1px solid #dee2e6;
}

.table-facility td:first-of-type, .table-facility th:first-of-type {
    padding-left:calc(35rem/16);
    @media screen and (max-width: 1199px) {
        padding-left:calc(5rem/16);
    }
}

.table-facility td {
    font-size:calc(15rem/16);
    border-top:none;
    vertical-align: bottom;
    @media screen and (max-width: 1199px) {
        padding-left:calc(13rem/16);
    }
}

.table-facility tr {
    border-bottom:1px solid #dee2e6;
}

.table-facility td.fz12 {
    font-size: calc(12rem/16);
}

.table-facility .icon {
    font-size:calc(22rem/16);
    margin-left:calc(10rem/16);
}