.slick:not(.slick-initialized) > div + div {
    display: none;
}


.slick-slider:not(.slick-initialized)   >  div  +  div  {
    display: none;
}
.slick-arrow {
    cursor:pointer;
    transform: opacity 120ms;
}
.slick-arrow.slick-disabled {
    opacity: 0.3;
    pointer-events:none;
}

.slick--no-arrows .slick-arrow {
    display: none !important; /*override inline style*/
}
.slick-thumbs .slick-slide {
    position:relative;
    cursor:pointer;
}
.slick-thumbs .slick-current:before {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: linear-gradient(89deg,rgba(0,0,0,.8),transparent);
    transition: opacity 120ms ease-out;
}
.slick-thumbs .slick-slide:hover,
.slick-thumbs .slick-slide:focus,
.slick-thumbs .slick-current  {
    opacity: 1;

}

.slick-vertical .slick-slide {
    border:none;
}