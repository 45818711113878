
.overlay-regions__brand {
    display:block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-left: 2.5625rem;
    margin-top: 2rem;
    @media screen and (max-width: 767px) {
        margin-left: calc(20rem/16);
    }
}
.overlay-regions__brand img {
    width: 13vw;
    @media screen and (min-width: 1920px) {
        width:calc(300rem/16);
    }
}

.overlay-regions__content {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 1;
    @media screen and (max-width: 1199px) {
        position:relative;
    }
}

.overlay-regions__content-text {
    padding-top:calc(20rem/16);
    padding-left:calc(130rem/16);
    max-width:35%;
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
        max-width:100%;
    }
    @media screen and (max-width: 1500px) {
        padding-left:calc(15rem/16);
        padding-right:calc(15rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        max-width:100%;
    }
}

.overlay-regions__headline {
    font-size:3.8vw;
    font-family:var(--font-headline);
    line-height:calc(70/78);
    letter-spacing: 1.38px;
    text-transform: uppercase;
    margin-bottom:calc(18rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(50rem/16);
        margin-bottom:calc(10rem/16);
    }
    @media screen and (min-width: 1920px) {
        font-size:calc(70rem/16);
    }
}

.overlay-regions__subheadline {
    font-family:var(--font-headline-italic);
    line-height:calc(49/46);
    letter-spacing: 0.92px;
    margin-bottom:calc(38rem/16);
    font-size: 2.3vw;
    @media screen and (max-width: 767px) {
        font-size:calc(26rem/16);
        margin-bottom:calc(20rem/16);
    }
    @media screen and (min-width: 1920px) {
        font-size:calc(50rem/16);
    }
}

.overlay-regions__text {
    font-size:calc(16rem/16);
    padding-right:calc(40rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
    }
}