
.hero {
    position: relative;
    overflow: hidden;
}

.hero__headline {
    font-size:calc(105rem/16);
    letter-spacing: 1.85px;
    font-family:var(--font-headline);
    text-transform: uppercase;
    line-height:1;
    @media screen and (max-width: 1400px) {
        font-size:calc(64rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(28rem/16);
    }
}

.hero-content .hero__headline {
    @media screen and (min-width: 1401px) {
        font-size:calc(78rem/16);

    }
}
.hero__content {
    position: absolute;
    max-width: calc(1380rem/16);
    padding:calc(100rem/16) calc(75rem/16);
    display:flex;
    align-items:flex-end;
    margin:0 auto;
    top:0;
    width:100%;
    height: calc(100%- 82px);
    left:0;
    right:0;
    z-index: 1;
    pointer-events: none;
    @media screen and (max-width: 1400px) {
        max-width: 100%;
        width: 100%;
        height: calc(100% - 120px);
    }
    @media screen and (max-width: 767px) {
        padding: 0px;
        height:100%;
        padding-bottom:calc(65rem/16);
    }
}

.hero__content .btn, .hero__content a {
    pointer-events: all;
}

@media screen and (min-width: 768px) {
    .hero__desc {
        font-size: 1.125rem;
        max-width: calc(500rem/16);
    }
}
.hero--full-height {
    min-height: 480px;
    height: calc(100vh + 82px);
    @media screen and (max-width: 767px) {
        height: calc(100vh - 53px)

    }
    @media screen and (min-width: 768px) {
        min-height: 800px;
    }
}

.hero--half .hero__content {
    width:auto !important;
    @media screen and (min-width: 768px) {
        left:50%;
        word-break:break-word;
    }
}

@media screen and (min-width: 768px) {
    .hero--half .hero__bg {
        width: 50%;
    }
    .hero--half .hero__bg:before {
        display: none;
    }
    .hero--half .hero__headline {

    }
}

@media screen and (max-width: 767px) {
    .hero--half .hero__headline {
        color:#fff;
        text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    }
}

.hero-content {
    height: calc(685rem/16);
    @media screen and (max-width: 767px) {
        height: calc(450rem/16);
    }
}
.hero__video-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    position: absolute;
}

.hero__video-wrapper video {
    width: auto;
    min-width: 100vw;
    height: auto;
    min-height: calc(100vh + 82px);
    left: 0;
    margin: 0 auto;
    right: 0;
    position: absolute;
}
.hero__bg {
    height: calc(100vh + 82px);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
}

.hero__bg.has-custom-position{
    @media screen and (max-width: 767px){
        background-position: var(--bg-position-xs);
    }
}

.hero__bg:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: radial-gradient(circle,#ffffff00 0,#000 100%);
    opacity: 0.3;
    left: 0;
    right: 0;
}
.hero--full-height .hero__bg {
    transform:scale(1.4);
}
.hero-content .hero__bg {
    max-height: calc(685rem/16);
    height:100%;

}
.hero--decorative-triangles {
    background-color:#fff;
}

.hero__scroll-down {
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    bottom: calc(10rem/16);
    font-size:calc(13rem/16);
    width:calc(90rem/16);
    text-align:center;
    text-shadow: 2px 0px 4px #000000;
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: 1.1px;
    color:#fff;
    z-index: 1;
}
.hero__scroll-down .icon {
    transition:all 0.2s ease;
    transform: translate(0, 0);

}
.hero__scroll-down:hover .icon,
.hero__scroll-down:focus-visible .icon {
    animation: moveDown 2s forwards infinite;
}
.hero__scroll-down:hover,
.hero__scroll-down:active,
.hero__scroll-down:focus,
.hero__scroll-down:focus-visible {
    color:#fff;
}

@keyframes moveDown {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.hero__scroll-down .icon {
    padding-top:calc(10rem/16);
}

.hero__subheadline {
    font-size:calc(60rem/16);
    letter-spacing:1.2px;
    line-height:1.2;
    @media screen and (max-width: 767px) {
        font-size:calc(24rem/16);
        line-height:calc(35/26)
    }
    @media screen and (max-width: 1400px) {
        font-size:calc(40rem/16);
    }
}

.hero-content .hero__subheadline {
    @media screen and (min-width: 1401px) {
        font-size:calc(46rem/16);

    }
}

.hero__decorative-triangle {
    height: 134px;
    overflow: hidden;
    bottom: 82px;
    position: absolute;
    left: 0;
    width: 100%;
    @media screen and (max-width: 767px) {
        height: calc(45rem/16);
        bottom: 0px;
    }
}
.hero__decorative-triangle:after {
    content: '';
    position: absolute;
    bottom: -82px;
    display: block;
    width: 0;
    z-index: 1;
    height: 0;
    border-style: solid;
    border-width: 0 0 219px 100vw;
    border-color: transparent transparent var(--color-primary) transparent;
    @media screen and (max-width: 768px) {
        border-width: 0 0 45px 100vw;
        bottom:0;
    }
}

.hero--decorative-triangles:after {
    content:'';
    position: absolute;
    bottom: 0;
    display:block;
    width: 0;
    z-index: 0;
    height: 0;
    border-style: solid;
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        bottom: -3px;
        border-width: 0 0 223px 100vw;
        border-color: transparent transparent #fff transparent;
    }
    @media screen and (min-width: 1200px) {
        border-width: 0 0 219px 100vw;
        border-color: transparent transparent #fff transparent;
    }
    @media screen and (max-width: 767px) {
        border-width: 0 0 20px 100vw;
        border-color: transparent transparent #fff transparent;
    }
}

.hero-content--breadcrumb-margin {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(-212rem/16);

    }
}
.hero__play {
    font-size: calc(60rem/16);
    color:#fff;
    display:block;
    margin-bottom: calc(30rem/16);
}


.start-animation .hero__bg, .start-animation .hero__slide-item {
    transform:scale(1.1);
    animation: invertedKenBurnsMobile 2s forwards;

    @media screen and (min-width: 768px){
        transform:scale(1);
        animation: invertedKenBurns 2s forwards;
    }
}
@keyframes invertedKenBurns {
    from{
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

@keyframes invertedKenBurnsMobile {
    from{
        transform: scale(1.1);
    }
    to {
        transform: scale(1);
    }
}


/* new Portal-Hero styling */
.hero--new.hero--full-height {
    height: 100vh;
}

.hero__secondary-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero__primary-bg {
    position: absolute;
    top: calc(166rem/16);
    right: 50%;
    left: auto;
    width: calc(636rem/16);
    transform: translateX(50%);
    
    @media (min-width: 768px) {
        top: 50%;
        transform: translate(50%, -50%);
        width: calc(1272rem/16);
    }

    @media (min-width: 1400px) {
        transform: translateY(-50%);
        right: clamp(3rem, 11.538vw - 7.096rem, 6.75rem);
    }
}

.hero--new .hero__primary-bg.hero__primary-bg--big {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    transform: none !important;
    animation: none !important;
}

.hero__primary-bg--big .hero__slider,
.hero__primary-bg--big .slick-list {
    height: 100%;
    width: 100%;
}

.hero__primary-bg,
.hero__primary-bg:not(.hero__primary-bg--big) .slick-list {
    height: calc(454rem/16);
    
    @media (min-width: 768px) {
        height: calc(808rem/16);
    }
}

.hero__bg--oberland,
.hero__primary-bg--oberland .slick-list {
    clip-path: var(--clip-path-oberland);
}

.hero__bg--nauders,
.hero__primary-bg--nauders .slick-list  {
    clip-path: var(--clip-path-nauders);
}

.hero__bg--kaunertal,
.hero__primary-bg--kaunertal .slick-list {
    clip-path: var(--clip-path-kaunertal);
}

.hero__primary-bg .hero__slider .slick-dots li:before {
    background: var(--primary-gradient);
    opacity: 0.5;
}

.hero__primary-bg .hero__slider .slick-dots li.slick-active:before {
    opacity: 1;
}

.hero__primary-bg .hero__slide-item:before {
    background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);
}

.hero__primary-bg .hero__slider .slick-dots {
    top: calc(100vh - 154rem/16);
    
    @media screen and (min-width: 768px) {
        right: calc(482rem/16);        
    }

    @media screen and (min-width: 992px) {
        right: calc(258rem/16);
    }

    @media screen and (min-width: 1200px) {
        right: calc(162rem/16);
    }

    @media screen and (min-width: 1400px) {
        right: calc(66rem/16);
    }
}

.hero--new .slick-track,
.hero--new .slick-slide > *,
.hero--new .hero__slide-item {
    height: 100%;
}

.hero--new .hero__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: calc(126rem/16) 0 0;
    padding: 0 calc(16rem/16) calc(16rem/16);
    height: calc(100vh - 126rem/16);

    @media (min-width: 768px) {
        margin-top: 0;
        justify-content: space-between;
        height: calc(100vh - 94rem/16);
        padding: calc(160rem/16) calc(75rem/16) 0;
    }
}

.hero--new .hero__content-top {
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 68%;
    }
}

.hero--new .hero__headline {
    font-family: var(--font-headline-italic);
    color: var(--color-dark);
    font-size: calc(48rem/16);
    line-height: calc(48/48);
    text-transform: none;
    
    @media (min-width: 768px) {
        font-size: clamp(2.5rem, 15.823vw - 5.095rem, 8.75rem);
        line-height: 1;
        margin-bottom: calc(48rem/16);
    }

    @media (min-width: 1400px) {
        font-size: calc(140rem/16);
        line-height: calc(150/140);
    }
}

.hero--new .hero__subheadline {
    color: var(--color-dark);
}

.hero__usps {
    display: flex;
    flex-direction: column;
    gap: calc(24rem/16);
    margin-top: calc(36rem/16);

    @media (min-width: 992px) {
        flex-direction: row;
        gap: calc(60rem/16);
    }
}

.hero__usp-item {
    display: flex;
    gap: calc(18rem/16);
    font-size: calc(14rem/16);
    line-height: calc(21/14);
}

.hero__usp-item-icon-wrapper {
    font-size: calc(10rem/16);
    height: calc(26rem/16);
    width: calc(36rem/16);
    background: var(--primary-gradient);
    position: relative;
    color: #fff;
}

.hero__usp-item-icon-wrapper .hero__usp-item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero--new.start-animation .hero__bg {
    transform: translate(50%, -50%) scale(1.1);
    animation: invertedKenBurnsMobileNew 2s forwards;

    @media screen and (min-width: 768px){
        transform: translateY(-50%) scale(1);
        animation: invertedKenBurnsNew 2s forwards;
    }
}
@keyframes invertedKenBurnsNew {
    from{
        transform: translateY(-50%) scale(1);
    }
    to {
        transform: translateY(-50%) scale(1.1);
    }
}

@keyframes invertedKenBurnsMobileNew {
    from{
        transform: translate(50%, -50%) scale(1.1);
    }
    to {
        transform: translate(50%, -50%) scale(1);
    }
}


/* new hero for content pages */
.hero-content.hero-content--new {
    margin-bottom: calc(-36rem/16);
    
    @media (min-width: 768px) {
        margin-bottom: calc(-132rem/16);        
    }
}

.hero-content--new.hero--decorative-triangles:after {
    content: none;
}

.hero-content--new .hero__bg:before {
    background: linear-gradient(180deg,hsla(0,0%,100%,0) 42%,hsla(0,0%,100%,.75) 64%,hsla(0,0%,100%,1));
    opacity: 1;
}

.hero-content--new .hero__content {
    @media (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.hero-content--new .hero__headline {
    font-family: var(--font-headline-italic);
    text-transform: none;
    color: var(--color-dark);
    font-size: calc(40rem/16);
    line-height: calc(48/40);

    @media (min-width: 768px) {
        font-size: calc(80rem/16) !important;
        line-height: calc(90/80) !important;
        margin-bottom: calc(12rem/16);
    }
}

.hero-content--new .hero__subheadline {
    font-family: 'Open Sans';
    font-weight: bold;
    color: var(--color-dark);
    font-size: calc(16rem/16);
    line-height: calc(20/16);

    @media (min-width: 768px) {
        font-size: calc(23rem/16) !important;
        line-height: calc(28/23) !important;
        letter-spacing: 0;
    }
}