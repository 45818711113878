
.multiline-link {
    font-size:calc(12rem/16);
    color:var(--color-primary);
    font-family: var(--font-default-bold);
    line-height:calc(17/12);
    position:relative;
    text-transform: uppercase;
    text-align:right;

}
.multiline-link__text {
    position:relative;
    z-index: 1;
    padding-left:10px;
    transition:all 0.2s ease-out;
}

.multiline-link__lines {
    position:relative;
    margin-bottom:0px;
    margin-right:4px;
    padding-left:10px;
}


.multiline-link:hover .multiline-link__lines {
    color:var(--color-primary-dark);

}
.multiline-link__text:before {
    content:'';
    display:block;
    position:absolute;
    bottom:-4px;
    right:0;
    background-color:var(--color-primary-light);
    width: 67%;
    height:calc(26rem/16);
    z-index: -1;
    transition:all 0.2s ease-out;
}

.multiline-link:hover .multiline-link__text {
    color:var(--color-primary-dark);
}
.multiline-link:hover .multiline-link__text:before {
    width:100%;
}

.multiline-link__icon {
    font-size:calc(50rem/16);
    padding-left:calc(11rem/16);
    padding-right:calc(11rem/16);
}

.multiline-link__infos {
    font-size:calc(12rem/16);
    color:var(--color-primary-light);
    padding-left:calc(12rem/16);

}