.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.pimcore_area_content + .content-block,
.content-block + .pimcore_area_content{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(72rem/16);
    }
}
