.loading-spinner.hidden {
    display: none;
}
.loading-spinner {
    font-size: calc(70rem/16);
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 0.5em auto;
    left: 0;
    right: 0;
    z-index: 1;
    border: 5px solid var(--color-primary-light);
    border-top-color: var(--color-primary);
    animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}