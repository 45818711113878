@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1741162145071/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1741162145071/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1741162145071/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-accommodation:before { content: "\EA01" }
.icon-addthis:before { content: "\EA02" }
.icon-app:before { content: "\EA03" }
.icon-archiv:before { content: "\EA04" }
.icon-arrow-back:before { content: "\EA05" }
.icon-arrow-down:before { content: "\EA06" }
.icon-arrow-next:before { content: "\EA07" }
.icon-arrow-paging-back:before { content: "\EA08" }
.icon-arrow-paging-next:before { content: "\EA09" }
.icon-arrow-txtlink:before { content: "\EA0A" }
.icon-bike:before { content: "\EA0B" }
.icon-biketrail:before { content: "\EA0C" }
.icon-cabin-color:before { content: "\EA0D" }
.icon-cabin:before { content: "\EA0E" }
.icon-car:before { content: "\EA0F" }
.icon-chairlift-2:before { content: "\EA10" }
.icon-chairlift-3:before { content: "\EA11" }
.icon-chairlift-4:before { content: "\EA12" }
.icon-chairlift-6:before { content: "\EA13" }
.icon-chairlift-8:before { content: "\EA14" }
.icon-check:before { content: "\EA15" }
.icon-closed:before { content: "\EA16" }
.icon-contact:before { content: "\EA17" }
.icon-delete:before { content: "\EA18" }
.icon-download:before { content: "\EA19" }
.icon-dub:before { content: "\EA1A" }
.icon-elements:before { content: "\EA1B" }
.icon-eub:before { content: "\EA1C" }
.icon-eub2:before { content: "\EA1D" }
.icon-eub3:before { content: "\EA1E" }
.icon-facebook:before { content: "\EA1F" }
.icon-facility-closed:before { content: "\EA20" }
.icon-facility-gondola:before { content: "\EA21" }
.icon-facility-open:before { content: "\EA22" }
.icon-facility-preparation:before { content: "\EA23" }
.icon-foerderband:before { content: "\EA24" }
.icon-fullscreen:before { content: "\EA25" }
.icon-gmap:before { content: "\EA26" }
.icon-gondola-color:before { content: "\EA27" }
.icon-gondola:before { content: "\EA28" }
.icon-google-plus:before { content: "\EA29" }
.icon-googlePlus:before { content: "\EA2A" }
.icon-grid:before { content: "\EA2B" }
.icon-hiking:before { content: "\EA2C" }
.icon-info:before { content: "\EA2D" }
.icon-instagram:before { content: "\EA2E" }
.icon-kinderlift:before { content: "\EA2F" }
.icon-lang-de:before { content: "\EA30" }
.icon-lang-en:before { content: "\EA31" }
.icon-langlaufen:before { content: "\EA32" }
.icon-linkedin:before { content: "\EA33" }
.icon-mail-btn:before { content: "\EA34" }
.icon-mail:before { content: "\EA35" }
.icon-map:before { content: "\EA36" }
.icon-menu:before { content: "\EA37" }
.icon-minus:before { content: "\EA38" }
.icon-orte:before { content: "\EA39" }
.icon-panorama:before { content: "\EA3A" }
.icon-phone:before { content: "\EA3B" }
.icon-picture:before { content: "\EA3C" }
.icon-pinterest:before { content: "\EA3D" }
.icon-plane:before { content: "\EA3E" }
.icon-play-button:before { content: "\EA3F" }
.icon-plus-bold:before { content: "\EA40" }
.icon-plus:before { content: "\EA41" }
.icon-poi:before { content: "\EA42" }
.icon-print:before { content: "\EA43" }
.icon-programm:before { content: "\EA44" }
.icon-rain:before { content: "\EA45" }
.icon-read-more:before { content: "\EA46" }
.icon-regions:before { content: "\EA47" }
.icon-ridingbike:before { content: "\EA48" }
.icon-search:before { content: "\EA49" }
.icon-sledge:before { content: "\EA4A" }
.icon-snow:before { content: "\EA4B" }
.icon-snowboarden:before { content: "\EA4C" }
.icon-standseilbahn:before { content: "\EA4D" }
.icon-stopwatch:before { content: "\EA4E" }
.icon-strecke:before { content: "\EA4F" }
.icon-sun:before { content: "\EA50" }
.icon-television:before { content: "\EA51" }
.icon-temperature:before { content: "\EA52" }
.icon-tickets:before { content: "\EA53" }
.icon-tour-length:before { content: "\EA54" }
.icon-tour-time:before { content: "\EA55" }
.icon-tour-up:before { content: "\EA56" }
.icon-touren:before { content: "\EA57" }
.icon-towlift:before { content: "\EA58" }
.icon-train:before { content: "\EA59" }
.icon-trekking:before { content: "\EA5A" }
.icon-tv-kaunertal:before { content: "\EA5B" }
.icon-twitter:before { content: "\EA5C" }
.icon-voucher:before { content: "\EA5D" }
.icon-weather-color:before { content: "\EA5E" }
.icon-weather:before { content: "\EA5F" }
.icon-webcam-color:before { content: "\EA60" }
.icon-webcam:before { content: "\EA61" }
.icon-whatsapp:before { content: "\EA62" }
.icon-wind:before { content: "\EA63" }
.icon-winterhiking:before { content: "\EA64" }
.icon-youtube:before { content: "\EA65" }
.icon-zoom-in:before { content: "\EA66" }
.icon-zoom-out:before { content: "\EA67" }
.icon-zub:before { content: "\EA68" }


:root {
--icon-accommodation: "\EA01";
    --icon-addthis: "\EA02";
    --icon-app: "\EA03";
    --icon-archiv: "\EA04";
    --icon-arrow-back: "\EA05";
    --icon-arrow-down: "\EA06";
    --icon-arrow-next: "\EA07";
    --icon-arrow-paging-back: "\EA08";
    --icon-arrow-paging-next: "\EA09";
    --icon-arrow-txtlink: "\EA0A";
    --icon-bike: "\EA0B";
    --icon-biketrail: "\EA0C";
    --icon-cabin-color: "\EA0D";
    --icon-cabin: "\EA0E";
    --icon-car: "\EA0F";
    --icon-chairlift-2: "\EA10";
    --icon-chairlift-3: "\EA11";
    --icon-chairlift-4: "\EA12";
    --icon-chairlift-6: "\EA13";
    --icon-chairlift-8: "\EA14";
    --icon-check: "\EA15";
    --icon-closed: "\EA16";
    --icon-contact: "\EA17";
    --icon-delete: "\EA18";
    --icon-download: "\EA19";
    --icon-dub: "\EA1A";
    --icon-elements: "\EA1B";
    --icon-eub: "\EA1C";
    --icon-eub2: "\EA1D";
    --icon-eub3: "\EA1E";
    --icon-facebook: "\EA1F";
    --icon-facility-closed: "\EA20";
    --icon-facility-gondola: "\EA21";
    --icon-facility-open: "\EA22";
    --icon-facility-preparation: "\EA23";
    --icon-foerderband: "\EA24";
    --icon-fullscreen: "\EA25";
    --icon-gmap: "\EA26";
    --icon-gondola-color: "\EA27";
    --icon-gondola: "\EA28";
    --icon-google-plus: "\EA29";
    --icon-googlePlus: "\EA2A";
    --icon-grid: "\EA2B";
    --icon-hiking: "\EA2C";
    --icon-info: "\EA2D";
    --icon-instagram: "\EA2E";
    --icon-kinderlift: "\EA2F";
    --icon-lang-de: "\EA30";
    --icon-lang-en: "\EA31";
    --icon-langlaufen: "\EA32";
    --icon-linkedin: "\EA33";
    --icon-mail-btn: "\EA34";
    --icon-mail: "\EA35";
    --icon-map: "\EA36";
    --icon-menu: "\EA37";
    --icon-minus: "\EA38";
    --icon-orte: "\EA39";
    --icon-panorama: "\EA3A";
    --icon-phone: "\EA3B";
    --icon-picture: "\EA3C";
    --icon-pinterest: "\EA3D";
    --icon-plane: "\EA3E";
    --icon-play-button: "\EA3F";
    --icon-plus-bold: "\EA40";
    --icon-plus: "\EA41";
    --icon-poi: "\EA42";
    --icon-print: "\EA43";
    --icon-programm: "\EA44";
    --icon-rain: "\EA45";
    --icon-read-more: "\EA46";
    --icon-regions: "\EA47";
    --icon-ridingbike: "\EA48";
    --icon-search: "\EA49";
    --icon-sledge: "\EA4A";
    --icon-snow: "\EA4B";
    --icon-snowboarden: "\EA4C";
    --icon-standseilbahn: "\EA4D";
    --icon-stopwatch: "\EA4E";
    --icon-strecke: "\EA4F";
    --icon-sun: "\EA50";
    --icon-television: "\EA51";
    --icon-temperature: "\EA52";
    --icon-tickets: "\EA53";
    --icon-tour-length: "\EA54";
    --icon-tour-time: "\EA55";
    --icon-tour-up: "\EA56";
    --icon-touren: "\EA57";
    --icon-towlift: "\EA58";
    --icon-train: "\EA59";
    --icon-trekking: "\EA5A";
    --icon-tv-kaunertal: "\EA5B";
    --icon-twitter: "\EA5C";
    --icon-voucher: "\EA5D";
    --icon-weather-color: "\EA5E";
    --icon-weather: "\EA5F";
    --icon-webcam-color: "\EA60";
    --icon-webcam: "\EA61";
    --icon-whatsapp: "\EA62";
    --icon-wind: "\EA63";
    --icon-winterhiking: "\EA64";
    --icon-youtube: "\EA65";
    --icon-zoom-in: "\EA66";
    --icon-zoom-out: "\EA67";
    --icon-zub: "\EA68";
    
}