.sponsor {
    background: var(--color-lightestgrey);
}

.sponsor__logo {
    padding: calc(24rem /16) calc(24rem /16) 0;

    @media screen and (max-width: 991px) {
        padding: calc(14rem /16) calc(14rem /16) 0;
    }
}

.sponsor__content {
    padding: calc(24rem /16);
    flex-grow: 1;
    @media screen and (max-width: 991px) {
        padding: calc(14rem /16);
    }
}

.sponsor__title {
    font-family: var(--font-default-bold);
    font-size: calc(24rem /16);
    line-height: 1.1;
    margin-bottom: calc(4rem/16);
    @media screen and (max-width: 991px) {
        font-size: calc(17rem /16);
    }
}

.sponsor__website {
    word-break: break-all;
    font-size: calc(12rem /16);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.sponsor__text{
    margin-top: calc(14rem /16);
    font-size: calc(12rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.sponsor__website:hover {
    text-decoration: underline;
}

.btn.sponsor__clipboard-btn {
    display: block;
    margin-top: calc(8rem /16);
    font-size: calc(11rem/16);
    padding: calc(10rem/16) calc(5rem/16);
    letter-spacing: 0.5px;

    @media screen and (min-width: 768px){
        font-size: calc(12rem/16);
        padding: calc(20rem/16);
        letter-spacing: 1px;
    }
}

.sponsor-area__voucher-header {
    padding: calc(10rem/16) calc(24rem /16);
    min-height: calc(65rem/16);
    font-size: calc(12rem/16);

    @media screen and (max-width: 991px) {
        padding: calc(12rem/16) calc(14rem /16);
    }

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.sponsor-area__voucher-body{
    padding: 0 calc(24rem /16) calc(10rem /16);
    background: var(--color-middlegrey);

    @media screen and (max-width: 991px) {
        padding: calc(8rem /16) calc(10rem /16) calc(14rem /16);
    }
}

.sponsor-area__voucher-icon{
    color: var(--color-primary);
    margin-right: calc(6rem/16);
    font-size: calc(16rem/16);
    display: none;

    @media screen and (min-width: 768px){
        display: block;
        font-size: calc(20rem/16);
    }
}

.sponsor-area__form-group{
    padding: calc(5rem/16) calc(5rem/16);
    background-color: #FFF;

    @media screen and (min-width: 768px){
        padding: calc(10.5rem/16) calc(18rem/16);
    }
}

.sponsor-area__form-group input{
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}

.sponsor-area__plus-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
    }
}

.sponsor-area__bg{
    background-repeat: repeat;

}