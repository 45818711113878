

.regional-overview__wrapper {
    max-width: 1920px;
    width:100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @media screen and (max-width: 767px) {
        position:relative;
        padding-left:calc(15rem/16);
        padding-right:calc(15rem/16);
    }
}

.regional-overview__item {
    position: absolute;
    z-index: 9;
    width:100%;
    max-width: 32vw;
    @media screen and (max-width: 767px) {
        position:relative;
        max-width:100%;
        padding:calc(20rem/16) calc(25rem/16) calc(15rem/16);
    }
    @media screen and (max-width: 1199px) {
        max-width: 46vw;
    }
    @media screen and (min-width: 1920px) {
        max-width: calc(550rem/16);
    }
}

.regional-overview__item .embed-responsive {
    border-radius: 50%;
}

.regional-overview__item--nauders{
    @media screen and (min-width: 767px) and (max-width: 1199px){
        top: 68%;
        left: 1%;
    }
    @media screen and (min-width: 1200px)  {
        top: 65%;
        left: 34%;
    }
}
.regional-overview__item--big.regional-overview__item--nauders {
    @media screen and (min-width: 1200px)  {
        top: 66%;
        left: 31%;
    }
}

.regional-overview__item--nauders .embed-responsive {
    border: 10px solid var(--color-nauders);
}

.regional-overview__item--kaunertal {
    @media screen and (min-width: 767px) and (max-width: 1199px){
        top: 64%;
        left: 50%;
    }
    @media screen and (min-width: 1200px) {
        top: 51%;
        left: 64%;
    }
}
.regional-overview__item--girodays{
    @media screen and (min-width: 767px) and (max-width: 1199px){
        top: 68%;
        left: 1%;
    }
    @media screen and (min-width: 1200px)  {
        top: 65%;
        left: 34%;
    }
}
.regional-overview__item--big.regional-overview__item--girodays {
    @media screen and (min-width: 1200px)  {
        top: 66%;
        left: 31%;
    }
}
.regional-overview__item--girodays .embed-responsive {
    border: 10px solid var(--color-primary);
}

.regional-overview__item--kaunertal .embed-responsive {
    border: 10px solid var(--color-kaunertal);
}

.regional-overview__item--tiroler-oberland {
    @media screen and (min-width: 767px) and (max-width: 1199px){
        top: 5%;
        left: 48.3%;
    }
    @media screen and (min-width: 1200px) {
        top: 17%;
        left: 63.3%;
    }
}

.regional-overview__item--tiroler-oberland .embed-responsive {
    border: 10px solid var(--color-tiroler-oberland);
}

.regional-overview__item--grey .embed-responsive {
    border: 10px solid var(--color-grey);
}

.regional-overview__item__headline {
    font-size:1.5vw;
    line-height:1;
    text-transform:uppercase;
}

.regional-overview__small-map {
    max-width: 50%;
}

.regional-overview__item .embed-responsive {
    max-width: calc(196rem/16);
}

.regional-overview__item--big .embed-responsive{
    max-width: calc(286rem/16);
}