
.content-video {
    cursor:pointer;
}

.content-video__content {
    background-color:var(--color-primary);
    color:#fff;
    height:100%;
    display:flex;
    flex-direction: column;
    position:relative;
    overflow:hidden;
    padding:calc(57rem/16) calc(28rem/16) calc(35rem/16);
}

.content-video--has-triangle .content-video__content:before {
    position:absolute;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 320px 31px 0;
    border-color: transparent var(--color-primary-dark) transparent transparent;
    z-index: 3;

}

.content-video__title {
    font-size:calc(35rem/16);
    line-height:calc(41rem/16);
    font-family:var(--font-default-bold);
    color:#fff;
}

.content-video__play-button {
    line-height:1;
    font-size:calc(60rem/16);
    cursor:pointer;
    transform-origin:left;
    transition:all 0.2s ease;
}

.content-video:hover .content-video__play-button {
    transform:scale(1.1);
}
