
.info-box {
    padding: calc(28rem/16) calc(10rem/16) calc(40rem/16);
    background-color: var(--color-dark);
    color:#fff;
    position:relative;
    overflow:hidden;
}

@media screen and (min-width: 768px) {
    .info-box:before {
        content:'';
        display:block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 213px 0 0 53px;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        left:0;
        bottom:0;
    }
    .info-box--two-rows:before {
        border-width: 430px 0 0 53px;
    }
    .info-box:after {
        content:'';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 25px 0 0 1100px;
        position: absolute;
        left:0;
        bottom:0;
        border-color: transparent transparent transparent #fff;
    }

}

.info-box__img {
    width:calc(115rem/16);
}

.info-box__text {
    line-height:calc(42rem/16);
    font-size:calc(15rem/16);
}

.info-box__img-text {
    padding-bottom: calc(20rem/16);
    padding-left:calc(5rem/16);
}

.info-box__img-text-big {
    font-size:calc(35rem/16);
    line-height:calc(42rem/16);
    font-family:var(--font-default-bold);
    position: relative;
}

.info-box__img-text-big:first-of-type:after {
    content:'';
    display:block;
    position:absolute;
    height:calc(2rem/16);
    width:calc(51rem/16);
    background-color:#fff;
}