/*package-teaser*/
.lp-packageteaser {
    background: var(--color-middlegrey);
}
.lp-packageteaser__content {
    padding:1rem 7.5rem 1rem calc(52rem/16);
    @media screen and (min-width: 1641px) {
        padding-right:1rem;
    }
    @media screen and (max-width: 767px) {
        padding:1.5rem;
    }
}
.lp-packageteaser__headline {
    letter-spacing: 0;
    margin-bottom: .375rem;
    font-family: var(--font-default-bold);
    font-size:1.25rem;
    @media screen and (min-width: 768px) {
        font-size:1.625rem;
    }
}
.lp-packageteaser__price {
    font-family: var(--font-default-bold);
    font-size:1.125rem;
    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}

/* accomodation teaser */
.lp-acco-teaser {
    border:1px solid var(--color-lightestgrey);
    height: 100%;

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
.lp-acco-teaser__body {
    padding:.75rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media screen and (min-width: 768px) {
        padding:1.5rem;
    }
}

.lp-acco-teaser__content {
    @media screen and (min-width: 768px) {
        margin-bottom: auto;
        flex-grow: 1;
    }
    @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
.lp-acco-teaser__headline {
    letter-spacing: 0;
    margin-bottom: .375rem;
    font-family: var(--font-default-bold);
    font-size:.9375rem;
    @media screen and (max-width: 767px) {
        margin-bottom: auto;
        flex-grow: 1;
    }
    @media screen and (min-width: 768px) {
        font-size:1.25rem;
    }
}

.lp-acco-teaser__wysiwyg {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
}

.lp-packageteaser__wysiwyg ul,
.lp-packageteaser__wysiwyg ol,
.lp-acco-teaser__wysiwyg ul,
.lp-acco-teaser__wysiwyg ol {
    margin:0 0 .5rem 2rem;
}

@media screen and (min-width: 768px) {
    .lp-packageteaser__wysiwyg ul {
        columns: 2;
        column-gap: 30px;
    }
}

.lp-packageteaser__wysiwyg p,
.lp-packageteaser__wysiwyg h1,
.lp-packageteaser__wysiwyg h2,
.lp-packageteaser__wysiwyg h3,
.lp-packageteaser__wysiwyg h4,
.lp-packageteaser__wysiwyg h5,
.lp-packageteaser__wysiwyg h6,
.lp-acco-teaser__wysiwyg p,
.lp-acco-teaser__wysiwyg h1,
.lp-acco-teaser__wysiwyg h2,
.lp-acco-teaser__wysiwyg h3,
.lp-acco-teaser__wysiwyg h4,
.lp-acco-teaser__wysiwyg h5,
.lp-acco-teaser__wysiwyg h6 {
    margin:0 0 .5rem 0;
}

.lp-packageteaser__wysiwyg p,
.lp-packageteaser__wysiwyg h1,
.lp-packageteaser__wysiwyg h2,
.lp-packageteaser__wysiwyg h3,
.lp-packageteaser__wysiwyg h4,
.lp-packageteaser__wysiwyg h5,
.lp-packageteaser__wysiwyg h6,
.lp-acco-teaser__wysiwyg h1,
.lp-acco-teaser__wysiwyg h2,
.lp-acco-teaser__wysiwyg h3,
.lp-acco-teaser__wysiwyg h4,
.lp-acco-teaser__wysiwyg h5,
.lp-acco-teaser__wysiwyg h6 {
    font-size: calc(16rem/16);
    text-transform: none;
    line-height: 1.25;
    font-family:var(--font-headline-italic);
    letter-spacing: 0;
    margin:0 0 .5rem 0;
}

.lp-acco-teaser__divider {
    height:.25rem;
    display: block;
    width:7rem;
    bottom: -.125rem;
    background-color:var(--color-primary);
}

.lp-acco-teaser__link {
    color:var(--color-primary);
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
    text-transform: uppercase;
    letter-spacing:1px;
}

.lp-acco-teaser__link > .icon {
    transform: translateX(0);
    transition:all 0.2s ease;
}
.lp-acco-teaser__link:hover > .icon,
.lp-acco-teaser__link:focus > .icon {
    transform: translateX(.5rem);
}

/*teaser in slider*/
.teaser-slider-mobile .lp-acco-teaser__body {
    padding:1.5rem;
}
.teaser-slider-mobile .lp-acco-teaser__headline {
    font-size:1.25rem;
}



.lp-wishlist-btn {
    white-space: normal;
    line-height: 1.33;
    position: relative;
    padding-left:3rem;
    padding-right:.75rem;
    width:100%;
    background-color:#fff;
    color:var(--color-primary);
    border:1px solid var(--color-primary);
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
    text-transform: uppercase;
    letter-spacing:1px;
    @media screen and (max-width: 767px) {
        padding:3.5rem .5rem 1.25rem .5rem;
    }
}
.lp-wishlist-btn:hover,
.lp-wishlist-btn:focus,
.lp-wishlist-btn.active {
    background-color:var(--color-primary);
    color:#fff;
}
.lp-wishlist-btn .custom-checkbox__box {
    position: absolute;
    left:.9375rem;
    top:50%;
    transform: translateY(-50%);
    margin-top: -1px;
    @media screen and (max-width: 767px) {
        left:50%;
        top:1rem;
        transform: translateY(0) translateX(-50%);
    }
}
.lp-wishlist-btn__active-text {
    display: none;
}
.lp-wishlist-btn.active .custom-checkbox__box:before {
    visibility: visible;
}
.lp-wishlist-btn.active .lp-wishlist-btn__default-text {
    display: none;
}
.lp-wishlist-btn.active .lp-wishlist-btn__active-text {
    display: inline;
}

.lp-wishlist__list-item {
    position: relative;
    background: #fff;
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
    text-transform: uppercase;
    letter-spacing:1px;
    padding:1rem 3.5rem 1rem 1.5rem;
    display: inline-block;
}
.lp-wishlist__list-item .lp-wishlist__remove {
    cursor: pointer;
    display: inline-block;
    webkit-appearance: none;
    outline: 0;
    border:0;
    padding:.5rem;
    font-size:1.375rem;
    position: absolute;
    right:.5rem;
    top:.375rem;
    background: transparent;
    color:var(--color-text-default);
}

.lp-stickybar {
    position: fixed;
    left:0;
    bottom:0;
    right:0;
    z-index:999;
    background:#fff;
    padding:1rem;
    box-shadow: 0 0px 15px rgba(0,0,0,.15);
    transform: translateY(10rem);
    transition:all 0.2s ease;
}
.lp-stickybar.is-visible {
    transform: translateY(0);
}


/*kontaktblock*/
.lp-contactblock {

}
.lp-contactblock__headline {
    font-family: var(--font-default-bold);
    font-size:1.25rem;
    @media screen and (min-width: 768px) {
        font-size:1.5rem;
    }
}
.lp-contactblock__image {
    display: block;
    overflow: hidden;
    border-radius: 100%;
    height:auto;
    width:calc(190rem/16);
    margin-bottom:1rem;
    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        margin-bottom:0;
    }
}