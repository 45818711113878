

.tab-button {
    height:calc(50rem/16);
    width:calc(50rem/16);
    background-color:var(--color-middlegrey);
    font-size:calc(20rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
    margin-left:calc(7rem/16);
    margin-bottom:calc(7rem/16);
}

.tab-button.active {
    background-color:#000;
    color:#fff;
}