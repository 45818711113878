html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
    overflow-x: hidden;
    max-width: calc(1920rem/16);
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0.625rem 1.875rem 0 rgba(0,0,0,.1);
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1140rem / 16);
    width: 100%;
    margin:0 auto;
    @media screen and (max-width:1199px)  and (min-width:767px){
        max-width: calc(100%);
        z-index: 9;
    }
    @media screen and (max-width: 1640px) and (min-width: 1200px){
        max-width: calc(100% - 140px);
    }

}

@media screen and (max-width: 767px){
    .container-xs {
        max-width: calc(1140rem / 16);
        width: 100%;
        margin:0 auto;
        padding-right: 15px;
        padding-left: 15px;
    }

}


.container.container--narrow {
    max-width: calc(940rem / 16);
}

.container.container--wide {
    max-width: calc(1340rem/16);
    width: 100%;
    @media (min-width:767px) and (max-height:1090px) and (max-width:1199px) {
        max-width: calc(100% - 70px);
        margin-left: calc(70rem / 16);
    }
    @media screen and (max-width:1199px)  and (min-width:767px) and (min-height:1091px){
        max-width: calc(100%);
    }
    @media screen and (max-width: 1640px) and (min-width: 1200px){
        max-width: calc(100% - 140px);
    }

}



.container.container--no-padding-mobile {
    @media screen and (max-width: 767px) {
        padding-left:0;
        padding-right:0;
    }
}

.content-page-title {
    padding-top:calc(112rem/16);
    position: relative;
    z-index: 1;
}

.container.container--move-left {
    @media (min-width: 768px) {
        max-width: calc(83.75rem + 50% - 83.75rem / 2);
        padding-right: 0;
        margin-right: 0;
    }
}