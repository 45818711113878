

.link {
    font-size:calc(12rem/16);
    font-family:var(--font-default-bold);
    letter-spacing: 1.02px;
    line-height: calc(17/12);
    padding-left:10px;
    padding-right:20px;
    position:relative;
    display:inline-block;
    text-transform: uppercase;
    transition: all 0.1s ease-out;
    margin-top:calc(15rem/16);
    color: var(--color-primary);
}

.link--has-arrow-before:before {
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
    content:var(--icon-arrow-right);
}

.link:hover {
    color:var(--color-primary-dark);
}