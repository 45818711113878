

.badge {
    padding: 3px 10px;
    margin-right: 3px;
    margin-bottom: 5px;
    font-family:var(--font-default);
    font-weight: normal;
    letter-spacing: 0.8px;
}
.badge-primary-light-transparent {
    background-color:var(--color-primary-light-transparent);
    color:var(--color-primary);
    font-size:calc(12rem/16);
}

.badge-rounded {
    border-radius:calc(30rem/16);
}

.badge-white {
    background-color:#fff;
    color:var(--color-primary);
}

.badge--big {
    padding: calc(12rem/16) calc(35rem/16);
    font-size: .8125rem;
    letter-spacing: 1px;
}