
.custom-modal {
    background-color: #f1f1f1;
}

.modal-caption {
    max-width:calc(1030rem/16);
    margin:0 auto;
    color:var(--color-text-default);
    padding:calc(100rem/16) calc(20rem/16);
}

.modal-caption__title {
    font-size:calc(23rem/16);
    font-family:var(--font-default-bold);
    line-height:calc(28/23);
    padding-bottom:calc(20rem/16);
    text-align:center;
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
        padding-bottom:calc(10rem/16);
    }
}

.modal-caption__title:after {
    content:'';
    display:block;
    width:calc(102rem/16);
    height:calc(4rem/16);
    background-color:var(--color-primary);
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    margin-top:calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-top:calc(5rem/16);
        height:calc(3rem/16);
    }
}

.modal-caption__infos {
    font-size:calc(15rem/16);
    font-family:var(--font-default);
    line-height:calc(27/15);
    letter-spacing: 1px;
    margin-bottom:calc(5rem/16);
    text-align:center;
    @media screen and (max-width: 767px) {
        font-size:calc(12rem/16);
        line-height:1.1;
    }
}

.modal-caption__gdpr-policy {
    margin-top:calc(18rem/16);
    padding-top:calc(18rem/16);
    border-top: 1px solid var(--color-primary);
}

.modal-close {
    color:var(--color-primary);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}


.modal-close:hover{
    color:var(--color-primary-light);
}

.modal-close {
    position:absolute;
    right: 20px;
    top: 20px;
    background-color: #fff;
    width: 4.375rem;
    height: 4.375rem;
    z-index: 9;
    box-shadow: none;
    border: none;
    color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    transition: all .2s ease;
    margin-top:calc(20rem/16);
    margin-right: calc(10rem/16);
}
.modal-close:after {
    content: var(--icon-closed);
}
