

.cta-link-box {
    background-color:var(--color-primary);
    color:#fff;
    transition:all 0.2s ease;
    padding:calc(20rem/16) calc(50rem/16);
    cursor:pointer;
    @media screen and (max-width: 767px) {
        padding:calc(5rem/16) calc(10rem/16);
    }
}

.cta-link-box:hover {
    background-color:#fff;
    color:var(--color-primary);
}

.cta-link-box__link {
    font-size:calc(20rem/16);
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
    }
}

.cta-link-box__icon {
    font-size:calc(45rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(27rem/16);
    }
}
.cta-link-box__icon.icon-mail {
    font-size:calc(35rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
    }
}