.select-has-dropdown__dropdown {
    visibility:hidden;
    display:none;
    background-color:#fff;
    border:1px solid var(--color-primary);
    padding:calc(25rem/16);
    position:absolute;
    width:calc(100% + 2px);
    margin-left:-1px;
    @media screen and (max-width: 767px) {
        position:relative;
    }
}

.select-has-dropdown.active .select-has-dropdown__dropdown {
    display:block;
    visibility:visible;
    z-index: 3;
}