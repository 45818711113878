
.navbar-wrapper {
    position: fixed;
    height: 100%;
    z-index: 9;
    @media screen and (max-width: 767px){
        position:relative;
    }
}

.navbar-meta {
    width: calc(166rem/16);
    height:100%;
    background:#fff;
    display:flex;
    align-items:flex-end;
    padding-left:calc(38rem/16);
    padding-right:calc(38rem/16);
    padding-bottom:calc(20rem/16);
    z-index: 9;
    @media screen and (max-width: 767px){
        padding:0;
        border-bottom:1px solid #E0E0E0;
        visibility:hidden;
        opacity:0;
        transform:translateX(-100px);
        width:0;
        display:none;
        transition:all 0.2s ease;
        border-right:0;

    }
}
.is-open .navbar-meta {
    @media screen and (max-width: 767px){
        width:calc(100% - 70px);
        height:calc(70rem/16);
        display:inline-flex;
        opacity:1;
        visibility:visible;
        transform:translateX(0px);
        transition:all 0.2s ease;
        z-index: 1;
    }
}

.navbar-meta .col {
    @media screen and (max-width: 767px){
        box-shadow:inset 1px 0px 0px 0px #E0E0E0;
    }
}

.navbar-meta__region-img {
    width:calc(90rem/16);
    @media screen and (max-width: 767px){
        height:calc(50rem/16);
    }
    @media screen and (max-width:1199px)  and (min-width:767px) {
        width:calc(70rem/16);

    }
}
.navbar-meta__region-img--bigger {
    width: calc(160rem/16);
    max-width: unset;
    @media screen and (max-width: 767px){
        width: auto; 
        height:calc(69rem/16);
        max-width: 100%;
    }
    @media screen and (max-width:1199px)  and (min-width:767px) {
        width:calc(160rem/16);

    }
}
.navbar-main {
    width: calc(166rem/16);
    background-color: #fff;
    position: fixed;
    padding: 0 calc(2rem/16) 0 0;
    top: 24%;

    @media screen and (max-width: 767px) {
        margin-left: calc(0rem/16);
        right:0;
        top:0%;
        left:0;
        position:absolute;
        width:100%;
        height:calc(70rem/16);
        background-color:#fff;
        border-bottom:1px solid var(--color-lightestgrey);
    }
    @media screen and (min-width: 768px) {
        z-index: 1000;
    }
    @media screen and (min-width: 1600px) {
        z-index: 998;
    }
}

.navbar-main.is-open {
    @media screen and (max-width: 767px) {
        width: 100%;
        left: 0;
        z-index:7;
    }
}

.navbar-brand {
    position: absolute;
    width:12.5vw;
    max-width:6rem;
    height:auto;
    z-index: 999;
    margin-left:calc(41rem/16);
    margin-top:calc(32rem/16);

    @media screen and (max-width: 767px) {
        width:calc(110rem/16);
        height:auto;
        margin-left:calc(10rem/16);
        margin-top:calc(10rem/16);
        position:absolute;
        z-index: 7;
    }
    @media screen and (max-width:1199px)  and (min-width:767px) {
        width: 14.5vw;
        margin-left: 2.5625rem;
    }
    @media screen and (min-width: 1600px) {
        max-width: 12rem
    }
}

.navbar-brand--nauders,
.navbar-brand--nauders img,
.navbar-brand--kaunertal,
.navbar-brand--kaunertal img {
    @media screen and (max-width: 767px) {
        width: calc(124rem / 16);
        height: calc(62rem/16);
    }
}

.navbar-brand--raceacrossthealps, .navbar-brand--gletscherkaiser, .navbar-brand--dreilaendergiro {
    max-width: 6rem;
    @media screen and (min-width: 1600px) {
        max-width: 10rem;
    }
}

.navbar-brand--pfunds,
.navbar-brand--pfunds img {
    max-width: 6rem;
    @media screen and (min-width: 1600px) {
        max-width: 10rem;
    }
}

.navbar-brand--pfunds {
    @media screen and (min-width: 1600px) {
        margin-left: 4rem;
    }
}

.navbar-brand--gletscherkaiser {
    @media screen and (min-width: 1200px) {
        margin-left: calc(14rem/16);
    }
}
.navbar-brand--endurotrails{
    @media screen and (max-width: 767px) {
        margin-left: .25rem;
        margin-top: 0;
        max-width: 6rem;
    }
    @media screen and (min-width: 1200px) {
        margin-left: 7.25rem;
    }
}

.navbar-brand--karriere{
    @media screen and (max-width: 767px) {
        margin-top: .25rem;
        max-width:13.5rem;
        width: 13.5rem;
    }
    @media screen and (min-width: 768px) {
        max-width: 23rem !important;
        width: 23rem !important;
    }
}
/*
.navbar-wrapper--portal .navbar-brand {
    @media screen and (max-width: 767px) {
        width:calc(110rem/16);
    }
}*/

.navbar-nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: calc(2rem/16);
    background-color: #fff;
    margin: 0 !important;
}

/* ----- navbar detail ------ */
.navbar__detail {
    transform:translateX(-100%);
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 22vw;
    left:0;
    opacity:0;
    visibility:hidden;
    transition: transform 300ms ease-in-out, visibility 0.001ms 300ms, opacity 300ms ease-in-out;

    @media screen and (max-width:767px) {
        width: 100%;
        overflow-y:scroll;
        overflow-x:hidden;
    }
    @media screen and (max-width:1400px)  and (min-width:768px){
        width: 27vw;
    }
}

.navbar__detail.active {
    pointer-events:all;
    z-index: 999;


}
.navbar__detail .navbar__detail-container {
    padding:0 0 calc(36rem/16);
    width:100%;

    @media screen and (max-width:767px) {
        padding: 0 calc(20rem/16);
        height:calc(100% - 85px);
        overflow-x:scroll;
    }
    @media screen and (max-width:1400px)  and (min-width:767px){
        padding:0 0 calc(36rem/16);
    }
}

.navbar-main.is-open .navbar__detail-first-level {
    left:calc(166rem/16);
    transform:translateX(0);
    box-shadow:inset -2px 0px 0px 0px #E0E0E0;
    visibility:visible;
    opacity:1;
    z-index: -1;
    display:flex;
    justify-content: flex-end;
    align-items:center;
    transition: transform 300ms ease-in-out, visibility 0.001ms, opacity 300ms ease-in-out;
    @media screen and (max-width:767px) {
        flex-direction: column;
        left:calc(0rem/16);
        margin-top: calc(70rem/16);
        padding-top: calc(22rem/16);
    }

}

.navbar-main .nav-item {
    padding-left:calc(75rem/16);
    padding-right:calc(60rem/16);
    @media screen and (max-width: 767px) {
        padding-left:0;
        padding-right:0;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-left:calc(20rem/16);
        padding-right:calc(10rem/16);
    }
}
.navbar__detail-first-level > .navbar__detail-container > ul > .nav-item {
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-left:calc(75rem/16);
        padding-right:calc(50rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-left:0;
        padding-right:calc(10rem/16);
    }
}
.navbar-main .nav-item:hover, .navbar-main .nav-item a:hover {
    color:var(--color-primary);
}
.nav-item.is-open > .navbar__detail {
    transform: translateX(100%);
    z-index: 3;
    visibility:visible;
    opacity:1;
    box-shadow:inset -1px 0px 0px 0px #E0E0E0;
    margin-left:-1px;
    transition: transform 320ms ease-out, visibility 0.001ms, opacity 180ms ease;

    @media screen and (max-width: 767px) {
        transform: translateX(0);
        position:absolute;

    }
}

.nav-item a:hover > .navbar__detail, .nav-item:hover > .navbar__detail {
    @media screen and (min-width: 767px) {
        transform: translateX(100%);
        z-index: 3;
        visibility:visible;
        opacity:1;
        box-shadow:inset -1px 0px 0px 0px #E0E0E0;
        margin-left:-1px;
        transition: transform 320ms ease-out, visibility 0.001ms, opacity 180ms ease;

    }
}

@media screen and (max-width: 767px) {
    .navbar-wrapper.not-is-affix {
        opacity:0;
        transition:opacity 0.2s ease;

    }
    .navbar-wrapper.is-affix {
        position:fixed;
        opacity:1;
        transition:opacity 0.2s ease;
        width:100%;
        height:calc(70rem/16);
        border-bottom:1px solid var(--color-lightestgrey);
    }
    .navbar-wrapper.is-affix.no-sticky-header{
        top: 0;
    }
}


.navbar__detail-second-level {
    z-index: 2;
    transform:translateX(100%);
    display:flex;
    justify-content: center;
    align-items:center;
    position:absolute;

}
.navbar__detail-third-level {
    z-index: 3;
    transform:translateX(100%);
    display:flex;
    justify-content: center;
    align-items:center;
    position:absolute;

}

.navbar-meta__lang-switch {
    @media screen and (max-width: 767px) {
        border-right: 1px solid #dee2e6;
    }
    @media screen and (min-width: 768px) {
        position: relative;
    }
}
.navbar-meta__lang-switch:after {
    @media screen and (min-width: 768px) {
        display: block;
        position: absolute;
        content: "";
        top: 2px;
        right: 0;
        width: 1px;
        height: 1.3125rem;
        background-color: #979797;
    }
}