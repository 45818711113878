.job-item {
    position: relative;
    padding:1.5rem;
    border:1px solid var(--color-grey);
    font-size:.875rem;
    letter-spacing: 1px;

    @media screen and (max-width: 767px) {
        padding:1rem;
    }
}
.job-item--square {
    height:100%;
    border:1px solid #fff;
    background: var(--color-middlegrey);

    @media screen and (min-width: 768px) {
        padding-top:3rem;
        padding-bottom:1.5rem;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.job-item--square .job-item__content {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.job-item__headline {
    letter-spacing: 0;
    margin-bottom: .375rem;
    font-family: var(--font-default-bold);
    font-size:1.125rem;
    @media screen and (min-width: 768px) {
        font-size:1.5rem;
        flex-grow: 1;
    }
}
.job-item__divider {
    height:.25rem;
    display: block;
    width:7rem;
    bottom: -.125rem;
    background-color:var(--color-primary);
}



/* modal */
.job-modal .modal-dialog {
    max-width: calc(960rem / 16);
    @media screen and (min-width: 1380px) {
        margin-top:5rem;
        max-width: calc(1360rem / 16);
    }
}
.job-modal .modal-content {
    border:0;
}
button.close.job-modal__close {
    margin: 0;
    font-size: 1rem;
    color:#fff;
    display: block;
    width:calc(40rem / 16);
    height:calc(40rem / 16);
    background: var(--color-primary);
    position: absolute;
    right:0;
    top:0;
    opacity: 1 !important;

    @media screen and (min-width: 768px) {
        width:calc(70rem / 16);
        height:calc(70rem / 16);
        font-size: 1.5rem;
    }
}
.job-modal__content {
    background: var(--color-lightestgrey);
    padding: calc(25rem/16) calc(20rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(70rem/16) calc(110rem/16);
    }
}
.job-modal__info {
    letter-spacing: 1px;
    font-size:.875rem;
}
.job-modal__divider {
    height:.25rem;
    display: block;
    width:7rem;
    background-color:var(--color-primary);
}


/* job themes */
.theme-nauders .job-item__divider,
.theme-nauders .btn-primary,
.theme-nauders .job-modal__divider,
.theme-nauders button.close.job-modal__close {
    background: var(--color-nauders);
}
.theme-nauders .text-primary {
    color: var(--color-nauders) !important;
}
.theme-nauders .text-primary:hover {
    color: #000 !important;
}
.theme-nauders .btn-primary {
    color:#fff;

    &:before {
        background: linear-gradient(90deg, var(--color-nauders) 0%, #012d6c 100%);
    }

    &:after {
       background: linear-gradient(90deg, transparent 0%, #012d6c 10%, var(--color-nauders) 100%);
    }
}
.theme-nauders .loading-spinner {
    border: 5px solid #fff;
    border-top-color: var(--color-nauders);
}


.theme-kaunertal .job-item__divider,
.theme-kaunertal .btn-primary,
.theme-kaunertal .job-modal__divider,
.theme-kaunertal button.close.job-modal__close {
    background: var(--color-kaunertal);
}
.theme-kaunertal .text-primary {
    color: var(--color-kaunertal) !important;
}
.theme-kaunertal .text-primary:hover {
    color: #000 !important;
}
.theme-kaunertal .btn-primary {
    color:#fff;

    &:before {
        background: linear-gradient(90deg, var(--color-kaunertal) 0%, #005890 100%);
    }

    &:after {
        background: linear-gradient(90deg, transparent 0%, #005890 10%, var(--color-kaunertal) 100%);
    }
}
.theme-kaunertal .loading-spinner {
    border: 5px solid var(--color-kaunertal);
    border-top-color: var(--color-kaunertal);
}
.theme-kaunertal .loading-spinner {
    border: 5px solid #fff;
    border-top-color: var(--color-nauders);
}


.theme-oberland .job-item__divider,
.theme-oberland .btn-primary,
.theme-oberland .job-modal__divider,
.theme-oberland button.close.job-modal__close {
    background: var(--color-tiroler-oberland);
}
.theme-oberland .text-primary {
    color: var(--color-tiroler-oberland) !important;
}
.theme-oberland .text-primary:hover {
    color: #000 !important;
}
.theme-oberland .btn-primary {
    color:#fff;

    &:before {
        background: linear-gradient(90deg, var(--color-tiroler-oberland) 0%, #DD6405 100%);
    }

    &:after {
        background: linear-gradient(90deg, transparent 0%, #DD6405 10%, var(--color-tiroler-oberland) 100%);
    }
}
.theme-oberland .loading-spinner {
    border: 5px solid var(--color-tiroler-oberland);
    border-top-color: var(--color-tiroler-oberland);
}
.theme-oberland .loading-spinner {
    border: 5px solid #fff;
    border-top-color: var(--color-tiroler-oberland);
}