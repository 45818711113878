

.lg-outer .lg-video {
    background-color:#000;
}

.lg-sub-html {
    padding-bottom:calc(40rem/16);
    font-family:var(--font-headline);
    font-size:calc(32rem/16);
}
.video-js .vjs-big-play-button{
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .video-js .vjs-play-control .vjs-icon-placeholder:before, .vjs-icon-play:before {
    display: flex;
    justify-content: center;
    align-items: center;
}