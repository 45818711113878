.sticky-header__placeholder {
    @media screen and (max-width: 767px) {
        display: none;
    }

    @media screen and (min-width: 768px) {
        position: relative;
        left: calc(166rem / 16);
    }
}

.sticky-header__placeholder.placeholder--sm {
    @media screen and (min-width: 768px) {
        height: calc(40rem / 16);
    }
}

.sticky-header__placeholder.placeholder--lg {
    @media screen and (min-width: 768px) {
        height: calc(80rem / 16);
    }
}

.sticky-header__content {
    padding-top: calc(70rem/16);
    margin-bottom: calc(-72rem/16);
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
        position: fixed;
        top: 0;
        right: 0;
        left: calc(166rem / 16);
        z-index: 5;
        padding-top: 0;
        margin-bottom: 0;
    }
}

.sticky-header__item {
    height: calc(44rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 calc(10rem / 16);
    @media screen and (min-width: 768px) {
        height: calc(40rem / 16);
    }
}

.sticky-header__item.bg-primary-dark {
    color: #FFFFFF;
}

.sticky-header__item.bg-white {
    color: var(--color-dark);
}

.sticky-header__item p {
    margin: 0;
    font-size: calc(10rem / 16);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
    }
}
