
.switch-regions-state-one, switch-regions-state-two {
    position: absolute;
    width: 100%;
    height: 100%;
}

.switch-regions--zoom-out {
    left: 72%;
    top: 19%;
    font-size: 1.8vw;
    @media screen and (min-width: 767px) and (max-width: 1199px) {
        left: 62%;
        top: 18%;
        font-size:calc(22rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(22rem/16);
    }
    @media screen and (min-width: 1920px) {
        font-size:calc(28rem/16);
    }
}

.switch-regions--zoom-in {
    top: 15%;
    left: 58%;
    z-index: 3;
    font-size: 1.8vw;
    @media screen and (min-width: 767px) and (max-width: 1199px) {
        left: 42%;
        font-size:calc(22rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(22rem/16);
    }
    @media screen and (min-width: 1920px) {
        font-size:calc(28rem/16);
    }
}

.switch-regions--plane {
    top: 27.3%;
    left: 74.9%;
    font-size: 2.1vw;
    @media screen and (min-width: 767px) and (max-width: 1199px) {
        left: 66.9%;
        font-size:calc(25rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(25rem/16);
    }
    @media screen and (min-width: 1920px) {
        font-size:calc(28rem/16);
    }
}

.regions__arrival-standalone .switch-regions--plane {
    left:88%;
}

.switch-regions--train {
    top: 37%;
    left: 76.5%;
    font-size: 2vw;
    @media screen and (min-width: 767px) and (max-width: 1199px) {
        left: 69.5%;
        font-size: calc(25rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(25rem/16);
    }
    @media screen and (min-width: 1920px) {
        font-size:calc(28rem/16);
    }
}
.regions__arrival-standalone .switch-regions--train {
    top: 40.5%;
    left: 92.5%;
}
.switch-regions--car {
    top: 47.3%;
    left: 76.7%;
    font-size: 1.3vw;
    z-index: 3;
    @media screen and (min-width: 767px) and (max-width: 1199px) {
        left: 70.2%;
        font-size: calc(18rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }
    @media screen and (min-width: 1920px) {
        font-size:calc(22rem/16);
    }
}
.regions__arrival-standalone .switch-regions--car {
    top: 54.3%;
    left: 93.7%;
}
.switch-regions {
    z-index: 3;
    position: absolute;
    transition: box-shadow 0.2s ease;
    width: 3.8vw;
    height: 3.8vw;
    min-width: calc(55rem / 16);
    min-height: calc(55rem / 16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    line-height: 1;
    align-items: center;
    background-color: #fff;
    color: var(--color-primary);
    @media screen and (min-width: 1920px) {
        width: calc(80rem/16);
        height: calc(80rem/16);
    }
}

.regions__arrival-standalone .switch-regions {
    box-shadow: 0px 1px 10px 0px rgba(50, 50, 50, 0.46);
}
.switch-regions.active {
    background-color: var(--color-primary);
    color: #fff;
}

.switch-regions:before {
    transition: box-shadow 0.2s ease;
    border-radius: 50%;
    bottom: 0;
    box-shadow: 0 0 0 0 #fff;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.switch-regions:hover:before {
    transform: scale(0.925);
    box-shadow: 0 0 0 3px var(--color-primary);
    opacity: 1;
}