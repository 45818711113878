/* Modal */

.registration-modal .modal-header {
    border: none;
    margin: 0 0.5rem;
}

.registration-modal .modal-title {
    font-family: var(--font-default-bold);
    font-size: 1.5rem;
    text-transform: uppercase;
    color: var(--color-primary);
}

.registration-modal .close {
    margin-top: -0.5rem;
}
.registration-modal__btn .icon {
    font-size: 1.25rem;
    color: var(--color-primary);
}

/* Table */

@media screen and (max-width: 767px){
    .registration-table th,
    .registration-table td {
        font-size: 0.875rem;
        padding: 0.5rem 0.25rem;
        word-break: break-all;
    }

    .registration-table thead th {
        letter-spacing: 0;
        text-transform: none;
    }
}
