.overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateX(-100%);
    transition: transform 450ms ease-in-out, visibility 0.001ms 450ms, opacity 450ms ease;
    background: white;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    padding-left:calc(166rem/16);
    color: var(--color-text-default);
    @media screen and (max-width:767px) {
        padding-left:0;
    }
}

.overlay--mobile {
    height: auto;
    bottom: calc(70rem/16);
    top: inherit;
    transform: translateY(100%);
    padding:calc(30rem/16);
    @media screen and (max-width:767px) {
        padding-left:calc(30rem/16);
    }
}

.overlay--dark {
    background-size: cover;
    background-color: transparent;
    background-image:url("/static/img/live-infos/live-bg-hover-1.svg");
    color:#fff;
}
.overlay-container {
    position:relative;
    height:100%;
    padding-top: calc(107rem/16);
    padding-bottom:calc(50rem/16);
    @media screen and (max-width:767px) {
        padding-top: calc(60rem/16);
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
        position:relative;

    }
}


.overlay-container.overlay-container--mt {
    margin-top:15%;
}
.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    z-index: 5;
    transition: transform 400ms ease-in-out, visibility 0.001ms, opacity 400ms ease;
    @media screen and (max-width: 767px) {
        z-index: 7;

    }
}

.overlay--full-width {
    transform: translateX(0);
    transition: visibility 0.001ms 600ms, opacity 600ms ease;
}

.overlay--full-width .overlay__content {
    max-width: calc(1920rem/16);
    margin:0 auto;
    width:100%;
}

.overlay--full-width.is-open {
    left:0;
    padding-left:0px;
    z-index: 999;
    overflow-y:auto;
    transition: visibility 0.001ms, opacity 600ms ease;
}

.overlay--mobile.is-open {
    padding:calc(30rem/16);
    padding-top:calc(80rem/16);
}
.overlay--decorative-triangle:after {
    content:'';
    position: absolute;
    bottom: -82px;
    display:block;
    width: 0;
    z-index: -1;
    height: 0;
    border-style: solid;
    transition:all 1.2s ease;
    border-width: 0 0 219px 100vw;
    border-color: transparent transparent var(--color-primary-middle-light) transparent;
    @media screen and (max-width: 767px) {
        display:none;
    }
}

.overlay__close {
    position:relative;
    float:right;
    right:20px;
    top:20px;
    background-color:#fff;
    width:calc(70rem/16);
    height:calc(70rem/16);
    z-index: 10;
    box-shadow:none;
    border:none;
    color:#000;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:calc(20rem/16);
    cursor:pointer;
    transition: all 0.2s ease;
    @media screen and (max-width:767px) {
        width:calc(50rem/16);
        height:calc(50rem/16);
        right:2px;
        top:2px;
    }
}

.overlay__close:hover {
    background-color:var(--color-primary-dark);
    color:#fff;
}

.overlay--mobile .overlay__close {
    background-color:transparent;
    color:var(--color-grey);
    left:initial;
    right:0px;
}

.overlay__header {
    height: 60px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 500;
    display: flex;
    align-items: center;
    font-size: 17px;
    padding: 0rem calc(20rem/16);
    font-family: var(--font-default-bold);
    flex: none;
}

.overlay__content {
    flex: auto;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.overlay.filter-overlay.is-open {
    z-index: 10;
}
.overlay__subheadline {
    font-size: 2.875rem;
    letter-spacing: .92px;
    @media screen and (max-width: 1199px) {
        font-size: 1.5rem;
    }
}