


.nav-pills {
    position:relative;
}
.nav-item{
    padding-left:calc(30rem/16);
    padding-right:calc(30rem/16);
}

.nav-link {
    font-size: calc(14rem/16);
    font-family:var(--font-default-bold);
    letter-spacing: 1.18px;
    line-height: calc(18/14);
    color:var(--color-primary-light);
    width:calc(190rem/16);
    text-align:center;
    position:relative;
    padding-bottom:calc(15rem/16);
    transition:all 0.2s ease;
}


.nav-link:before {
    content:'';
    display:block;
    position:absolute;
    bottom:0;
    width:100%;
    left:0;
    height:calc(1rem/16);
    background-color:#fff;
    transition:background-color 0.3s, height 210ms ease;
}

.nav-link.active:before, .nav-link:hover:before {
    height:calc(4rem/16);
    background-color:var(--color-primary);
}

.nav-link.active, .nav-link:hover {
    color:var(--color-primary);
}

.nav-pills--dark .nav-link {
    color:#9B9B9B;
    @media screen and (max-width: 1199px) {
        width:auto;
    }

}
.nav-pills--dark .nav-link.active {
    color:#000;
}

.nav-pills--dark .nav-link.active:before,.nav-pills--dark .nav-link:hover:before {
    background-color:#000;
}
