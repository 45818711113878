
.teaser-row-slider {
    position:relative;
}

.teaser-row-slider--has-arrows .teaser-row__content {
    max-width:100%;
    margin-left:0px;
}

.teaser-row-slider__arrow {
    color:var(--color-primary);
    border:1px solid var(--color-primary);
    font-size:calc(16rem/16);
    padding: 14px 15px;
    align-items: center;
    justify-content: center;
    display:flex;
    background-color:#fff;
    width:calc(75rem/16);
}

.teaser-row-slider .slide__item {
    position: relative;
    border-left:1px solid #fff;
    border-top:1px solid #fff;
    border-bottom:1px solid #fff;
}

/* because last teaser is cut off: https://elements.my.workfront.com/task/view?ID=5cd530190377051ceaeb3ab67c4b78b6*/
.teaser-row-slider .slick-list {
    @media screen and (max-width:1199px)  and (min-width:767px) {
        margin-left:calc(15rem/16);
    }
    @media screen and (min-width: 1200px) {
        margin-left:calc(70rem/16);
    }
}

.teaser-row-slider__arrow.arrow-prev {
    margin-bottom:10px;
}

.teaser-row-slider__arrows {
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    right:15px;
}


.teaser-row-slider--has-arrows .teaser:before {
    @media screen and (min-width: 768px) {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);
        top: 0;
        left: 0;
        transition: opacity 0.3s ease;
        z-index: 2;
    }
}
.teaser-row-slider--has-arrows .slick-active .teaser:before,
.teaser-row-slider--has-arrows .slick-active .teaser:before {
    opacity:0;
}

/* new slider styling */
.teaser-row-slider.teaser-row-slider--new:not(.teaser-row-slider--no-decorator) .slick-list {
    @media (min-width: 768px) {
        &:before {
            content: "";
            display: block;
            height: 100%;
            width: 10%;
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            z-index: 1;
        }
    }
    
    @media (min-width: 1200px) {
        margin-left: 0;
        padding-left: calc(70rem/16);
    }
}

.teaser-row-slider.teaser-row-slider--new .slick-track {
    gap: calc(12rem/16);
    display: flex;
}

.teaser-row-slider.teaser-row-slider--new .slide__item {
    border: 0;
}

.teaser-row-slider--new.teaser-row-slider--has-arrows .teaser:before {
    @media (min-width: 768px) {
        content: none;
    }
}

.teaser-row-slider.teaser-row-slider--new .teaser-row-slider__arrows {
    @media (max-width: 767px) {
        position: relative;
        transform: none;
        display: flex;
        justify-content: center;
        gap: calc(12rem/16);
        right: 0;
        margin-top: calc(12rem/16);
    }
}

.teaser-row-slider.teaser-row-slider--new .teaser-row-slider__arrow {
    padding: calc(16rem/16) calc(42rem/16);
    width: fit-content;
    border-width: calc(2rem/16);
}

.teaser-row-slider.teaser-row-slider--new .teaser-row-slider__arrow.arrow-prev {
    @media (max-width: 767px) {
        margin-bottom: 0;
    }
}

.teaser-row-slider.teaser-row-slider--new .slick-arrow.slick-disabled {
    opacity: 1;
    color: #dcdcdc;
    border-color: #fff;
}

.teaser-row--new.teaser-row-slider .slide__item {
    border: none;
}