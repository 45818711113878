.img-text-teaser {
    padding-bottom:calc(20rem/16);
}
.img-text-teaser__body {
    border:1px solid var(--color-middlegrey);
    padding:calc(26rem/16);
    color:#000;
    transition:all 0.2s ease;
    background-color:#fff;
}

.img-text-teaser:hover .img-text-teaser__body {
    background-color:var(--color-middlegrey);
}
.img-text-teaser__body .teaser__category, .img-text-teaser__body .teaser__title, .img-text-teaser__body .teaser__description {
    text-shadow:none;
}

.img-text-teaser .teaser__link:before {
    background-color:var(--color-primary-light);
    @media screen and (max-width: 767px) {
        height: .625rem;
    }
}
.img-text-teaser .teaser__link-text {
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        opacity:1;
    }
}

/* new img-text-teaser styling */
.img-text-teaser--new .teaser__link {
    padding: 0;

    &
    :before {
        content: none;
    }
}

.img-text-teaser--new .teaser__link-text {
    opacity: 1;
}