
.teaser__category {
    text-shadow: 0 1px 2px rgba(0,0,0,0.2);
    font-size:calc(14rem/16);
    line-height:calc(19/14);
    margin-bottom:calc(5rem/16);
    text-transform: uppercase;
    letter-spacing:1px;

}

.teaser__title {
    font-size:calc(23rem/16);
    text-shadow: 0 1px 2px rgba(0,0,0,0.2);
    line-height:calc(28/23);
    font-family:var(--font-default-bold);
    margin-bottom:calc(5rem/16);
    @media screen and (max-width: 1400px) {
        font-size:calc(16rem/16);
    }
}

.teaser__decorator {
    margin-top: calc(12rem/16);
    height: calc(4rem/16);
    width: calc(120rem/16);
    background: var(--primary-gradient);
    transform-origin: left;
    transition: transform 0.3s ease-out;
}

.teaser:hover .teaser__decorator {
    transform: scaleX(1.5);
}

.teaser-title--has-line {
    position:relative;
    margin-bottom:10px;
}
.teaser-title--has-line:after {
    content:'';
    display:block;
    position:absolute;
    width: calc(102rem/16);
    height:calc(4rem/16);
    background-color:var(--color-primary);
    bottom:calc(-10rem/16);
    left:0;
    right:0;
    margin:0 auto;
}
.img-teaser__body--bottom .teaser__title {
    font-size:calc(26rem/16);
    @media screen and (max-width: 1400px) {
        font-size:calc(18rem/16);
    }
}

.teaser__link-with-icon {
    font-family:var(--font-headline-italic);
    position:relative;
    z-index: 1;
    line-height:1;
    padding-left:calc(14rem/16);
    text-align:center;
    padding-right:calc(14rem/16);
    padding-bottom:calc(3rem/16);
}

.teaser__link-with-icon:before {
    content:'';
    display:block;
    bottom:0;
    position:absolute;
    height:calc(26rem/16);
    background-color:var(--color-primary);
    width:calc(108rem/16);
    z-index: -1;
    right:0;
}

.teaser__offer-text--big {
    font-size:calc(40rem/16);
}

.teaser--has-triangle {
    overflow:hidden;
}

.teaser--has-triangle:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 320px 31px 0;
    border-color: transparent var(--color-booking) transparent transparent;
    z-index: 2;
}
.teaser--has-triangles-white--bottom {
    overflow:hidden;
    position:relative;
}
.teaser--has-triangles-white--bottom:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 0px 220px;
    border-color: transparent transparent #fff transparent;
    z-index: 3;
    transition: all 0.2s ease;
}
.teaser--has-triangles-white--bottom:hover:before {
    border-width: 0 0 21px 220px;

}

.teaser__link-with-icon__icon {
    font-size:calc(35rem/16);
    padding-left: calc(10rem/16);
    padding-bottom: 5px;
}
.teaser__link-with-icon__icon--rotated {
    transform:rotate(-30deg);
}

.teaser__description {
    padding-top:calc(13rem/16);
    font-size:calc(14rem/16);
    line-height:calc(19/14);
    font-family:var(--font-default);
    text-shadow: 0 1px 2px rgba(0,0,0,.2);
    padding-right:calc(30rem/16);
    letter-spacing:1px;

}

.teaser__location {
    letter-spacing: 0.88px;
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
    line-height:calc(14/12);
    z-index: 1;
    text-shadow: 0 1px 4px rgba(0,0,0,0.8);
}

.teaser__link {
    font-size:calc(12rem/16);
    font-family:var(--font-default-bold);
    letter-spacing: 1.02px;
    line-height: calc(17/12);
    padding-left:10px;
    padding-right:20px;
    position:relative;
    display:inline-block;
    z-index: 1;
    color:#fff;
    text-transform: uppercase;
    transition: all 0.1s ease-out;
}
.teaser__link:hover {
    color:#fff;
}
.teaser__link-text {
    opacity:0;
    letter-spacing:1.1px;
    transition: all 0.1s ease-out;
}
.teaser__link:focus-visible .teaser__link-text{
    opacity: 1;
}

.teaser:hover .teaser__link-text,
.teaser:focus .teaser__link-text{
    opacity:1;
}

.teaser__link:before {
    height: 4px;
    content: "";
    display: block;
    width: 100%;
    bottom: -2px;
    z-index: -1;
    position: absolute;
    margin-left: -10px;
    transition: all 0.1s ease-out;
    background-color:var(--color-primary);
}

.teaser:hover .teaser__link:before {
    height: .625rem;
}

.teaser:hover .teaser__hover-text {
    opacity: 1;
    z-index: 1;
    color: #fff;
}

.teaser__hover-text {
    letter-spacing: 0.6px;
    @media screen and (min-width: 1200px) {
        transition: opacity 0.2s ease-out;
        opacity: 0;
        font-size: calc(15rem / 16);
        font-family: var(--font-default-bold);
    }

}

.teaser-row__content .img-teaser .teaser__hover-text {
    @media screen and (max-width: 1439px) {
        display:none;
    }
}
.teaser--has-hover-overlay {
    overflow:hidden;
}

.teaser--has-hover-overlay:after {
    @media screen and (min-width: 767px) {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        background: rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease-out;
    }

}

.teaser--has-hover-overlay:hover:after {
    @media screen and (min-width: 767px) {
        opacity: 1;
    }
}

.teaser__highlight-badge {
    background-color:#FFCC00;
    color:var(--color-text-default);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    font-size:calc(12rem/16);
    position:absolute;
    top:0;
    font-family:var(--font-default-bold);
    right:0;
    height:calc(30rem/16);
    width:calc(104rem/16);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(5rem/16);
    text-align:center;
    line-height:1;
}

.teaser__highlight-badge:before {
    content: '';
    display: block;
    position: absolute;
    left: -3px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3px 30px 0;
    border-color: transparent #FFCC00 transparent transparent;
}

.teaser__highlight-badge:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 104px 10px 0;
    border-color: transparent #FFCC00 transparent transparent;
}