
.breadcrumb-wrapper {
    display: flex;
    align-items: flex-end;
    width: 100%;
}

.breadcrumb-wrapper .row {
    width: 100%;
}
.breadcrumb {
    font-size:calc(12rem/16);
  //  height:calc(126rem/16);
    display:flex;
    align-items:flex-end;
    z-index: 1;
    padding-bottom:calc(5rem/16);
    justify-content: end;
}

.breadcrumb--has-triangle {
    position:relative;
    height:calc(134rem/16);
}

.breadcrumb--has-triangle:before {
    content:'';
    display:block;
    position:absolute;
    top: 0px;
    left: 33.8%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 134px 60vw;
    border-color: transparent transparent #EFEFEF;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        left: 36.8%;
    }
}


.breadcrumb--after-hero.breadcrumb--has-triangle:before {
    left: 30vw;
    border-width: 0 0 134px 62vw;
}

.breadcrumb-item:before {
    content:'';
}