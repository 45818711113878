/*Margin Helper*/


/*Padding Helper*/
.spacer--for-nav {
    @media screen and (max-width:767px) {
        max-width:100%;
    }
    @media screen and (min-width: 1200px) {
        padding-left:calc(70rem/16);
        max-width:100%;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-left:calc(15rem/16);
        padding-right:calc(15rem/16);
        max-width:100%;
    }
}


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-normal {
    text-transform: initial;
}

/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-blue { color: var(--color-blue); }
.text-yellow { color: var(--color-yellow); }

.text-nauders { color:var(--color-nauders);}
.text-kaunertal { color:var(--color-kaunertal);}
.text-tiroler-oberland { color:var(--color-tiroler-oberland);}
.text-darkgrey { color:var(--color-darkgrey); }
.text-primary { color:var(--color-primary) !important; }
.text-primary-light { color:var(--color-primary-light); }
/*Background Color Helper*/
.bg-booking { background-color: var(--color-booking); }
.bg-primary-light { background-color: var(--color-primary-light); }
.bg-primary-middle-light { background-color: var(--color-primary-middle-light); }
.bg-primary-light-transparent { background-color: var(--color-primary-light-transparent); }
.bg-regions { background-color: var(--color-regions-bg); }
.bg-dark-opacity {
    background-color: rgba(0,0,0,0.85);
}
.bg-lightestgrey {
    background-color:var(--color-lightestgrey);
}
.bg-middlegrey {
    background-color:var(--color-middlegrey);
}
.bg-grey {
    background-color:var(--color-grey);
}
.bg-grey-light {
    background-color:var(--color-grey-light);
}
.bg-touren {
    background-size:cover;
}
.bg-primary-transparent {
    background-color:var(--color-primary-light-transparent);
}
.bg-primary-middle {
    background-color:var(--color-primary-middle-light);
}
.bg-primary-dark {
    background-color:var(--color-primary-dark);
}
.bg-primary-booking-dark {
    background-color:var(--color-booking-hover);
}
.bg-darkgrey {
    background-color:var(--color-darkgrey);
}
.bg-touren:before {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    background-color:var(--color-overlay);
    opacity: 0.7;
    z-index: -1;
}
.bg-overlay {
    background-color: var(--color-overlay);
}

.bg-kaunertal { background-color: var(--color-kaunertal);}
.bg-nauders { background-color: var(--color-nauders);}
.bg-tiroler-oberland { background-color: var(--color-tiroler-oberland);}


/* Font Helper */
.font-headline {font-family:var(--font-headline); }
.font-headline-italic {font-family:var(--font-headline-italic); }
.font-default {font-family:var(--font-default); }
.font-default-bold {font-family:var(--font-default-bold); }

.border--dark { border-color: #000 !important; }
.border--kaunertal { border-color: var(--color-kaunertal)!important;}
.border--nauders { border-color: var(--color-nauders)!important;}
.border--tiroler-oberland { border-color: var(--color-tiroler-oberland)!important;}
.border--booking { border-color: var(--color-booking) !important;}
.border--primary { border-color: var(--color-primary) !important;}

.border--after { position:relative; }
.border--after:after {
    display:block;
    position:absolute;
    content:'';
    top:2px;
    right:0;
    width:1px;
    height:calc(21rem/16);
    background-color: #979797;;
}
.fz8 { font-size: calc(8rem/16); }
.fz10 { font-size: calc(10rem/16); }
.fz12 { font-size: calc(12rem/16); }
.fz-md-16 {
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
    }
}
.fz14 { font-size: calc(14rem/16); }
.fz22 { font-size: calc(22rem/16); }
.fz21 { font-size: calc(21rem/16); }
.fz24 { font-size: calc(24rem/16); }
.fz25 { font-size: calc(25rem/16); }
.fz27 { font-size: calc(27rem/16); }
.fz40{ font-size: calc(40rem/16); }
.fz45{ font-size: calc(45rem/16); }
.fz18 { font-size: calc(18rem/16); }
.fz78 { font-size:calc(78rem/16);}
.rotate-270 { transform: rotate(270deg);}

.text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.isCursor {
    cursor:pointer;
}

.lh-1 {
    line-height:1;
}

.text-icon {
    padding-left: 2rem;
    position: relative;
    font-size:1rem;
    text-decoration:none;
    @media screen and (min-width: 768px) {
        font-size:1.125rem;
    }
}
.text-icon--link {
    color:var(--color-primary);
    text-decoration:none;
}
.text-icon--link-plain {
    color:inherit;
    text-decoration:none;
}
.text-icon .icon {
    position: absolute;
    left:0;
    top:0;
    font-size: 1.375rem;
    color:var(--color-primary);
}
.text-icon .icon-mail {
    font-size: 1rem;
    top:.25rem;
}


.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-40 { width: 40%; }
.w-md-auto {
    @media screen and (min-width: 768px) {
        width: auto !important;
    }
}
.h-md-auto {
    @media screen and (min-width: 768px) {
        height: auto !important;
    }
}
.h-md-initial {
    @media screen and (min-width: 768px) {
        height: initial !important;
    }
}

@media screen and (min-width: 768px) {
    .col-md-20-perc {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}


.border-primary-light { border-color:var(--color-primary-light);}
.border-md-right-none {
    @media screen and (min-width: 768px) {
        border-right: none!important;
    }
}

.flex-1 {
    flex:1;
}

.ls-1 {
    letter-spacing:1px;
}

.max-width-20 { max-width: 20%; }


.body-freeze {
    @media screen and (max-width: 767px) {
        -webkit-overflow-scrolling: touch;
        overflow:auto;
        height:100%;
    }
}

.btn--min-width{
    min-width: calc(190rem/16);
}

.w-md-75{
    @media screen and (min-width: 768px){
        width: 75%;
    }
}

.teaser-slider-mobile {
    margin-right:-15px !important;
}
.teaser-slider-mobile .slick-slide:not(.slick-current) {
    opacity: .5;
}


.white-space-normal {white-space: normal}

.lang-circle {
    min-width: 2.3em;
    min-height: 2.3em;
    text-align: center;
}

/*Skiplinks*/
a.skiplink {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 1000;
    font-size: 14px;
    padding: 3px;
}
a.skiplink:focus-visible,
a.skiplink:focus {
    left: 0;
    top: 0;
    outline: none;
    background-color: var(--color-primary);
    color: #fff;
}

#warning-static-release {
    right:auto !important;
    left:-100000rem !important;
}

.clip-path-oberland {
    clip-path: var(--clip-path-oberland);
}

.clip-path-nauders {
    clip-path: var(--clip-path-nauders);
}

.clip-path-kaunertal {
    clip-path: var(--clip-path-kaunertal);
}

.gap-1 {
    gap: calc(4rem/16);
}

.z-index-0 {
    z-index: 0;
}