
.page-link .icon {
    font-size:calc(6rem/16);
    margin-right:calc(10rem/16);
    margin-left:calc(10rem/16);
    color:var(--color-text-default);
    @media screen and (max-width: 767px) {
        margin:0;
    }
}

.page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:var(--font-default-bold);
    height:100%;
}

.page-link__next, .page-link__prev {
    text-transform: uppercase;
    font-family:var(--font-default);
}

.page-link {
    @media screen and (max-width: 767px) {
        padding:calc(5rem/16) calc(10rem/16);
    }
}