#vee24LiveHelpTabHTML {
    display: none !important;
}
.live-help__button {
    position: fixed;
    bottom: 30%;
    width: calc(70rem/16);
    height: calc(70rem/16);
    background-color: var(--color-primary);
    color: #fff;
    z-index: 4;
    right: 0;
    box-shadow: 0 0 5px 5px #57595a0f;
    border: none;
    text-transform:uppercase;
    letter-spacing: 1.1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .625rem;
    line-height: 1;
    text-align: center;
    opacity:0;
    transform: translateX(100%);
    font-family: var(--font-default);
    transition: background-color .2s ease;
    cursor: pointer;
    @media screen and (max-width: 767px) {
        bottom: 55%;
        width: calc(60rem/16);
        height: calc(60rem/16);
        padding: calc(5rem/16);
    }
}
@media screen and (min-width: 768px) {
    .live-help__button:hover {
        background-color: var(--color-primary-dark);
    }
}
.live-help__button.start-animation {
    animation: liveHelp 0.4s 1.5s ease-out forwards;
}
.live-help__button-icon {
    font-size: 1.875rem;
    display: block;
    text-align:center;
    margin-right:0;
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(25rem/16);
        margin-bottom: calc(10rem/16);
    }
}

@keyframes liveHelp {
    from{
        opacity:0;
        transform: translateX(100%);
    }
    to {
        opacity:1;
        transform: translateX(0);
    }
}